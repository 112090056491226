// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mantine-o3u3mq {
  background-color: #fcfcfc;
  border: 0.0625rem solid #b5a9495c;
}

.mantine-o3u3mq[data-active] {
  background-color: #fff;
  border-color: #a1974b5c;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/FaqPage.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iCAAiC;AACnC;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".mantine-o3u3mq {\n  background-color: #fcfcfc;\n  border: 0.0625rem solid #b5a9495c;\n}\n\n.mantine-o3u3mq[data-active] {\n  background-color: #fff;\n  border-color: #a1974b5c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
