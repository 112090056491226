import {
  createStyles,
  Header,
  HoverCard,
  Group,
  UnstyledButton,
  Text,
  SimpleGrid,
  ThemeIcon,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconNews, IconWallet, IconChevronDown } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import "../../assets/css/Header.css";
import { useSelector } from "react-redux";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenNav: {
    background: "red",
  },

  hiddenMobile: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },
}));

const mockdata = [
  {
    icon: IconWallet,
    title: "Portfolio",
  },
  {
    icon: IconNews,
    title: "Notes",
  },
];

export function PageHeader() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const { classes, theme } = useStyles();
  const isAuth = useSelector((state) => state.auth.isAuth);

  const links = mockdata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group noWrap align="flex-start">
        <ThemeIcon bg={"#fff"} size={30} variant="default" radius="md">
          <item.icon color={"#a1974b"} size={rem(20)} />
        </ThemeIcon>
        <div>
          <Text size="sm" fw={500}>
            {item.title}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));

  return (
    <Box pb={120}>
      <Header height={60} px="md">
        <Group position="apart" sx={{ height: "100%" }}>
          <div className="header-logo">InnerMind</div>
          <Group
            sx={{ height: "100%" }}
            spacing={0}
            className={classes.hiddenMobile}
          >
            <Link to="/" className={classes.link}>
              Accueil
            </Link>
            <HoverCard
              width={600}
              position="bottom"
              radius="md"
              shadow="md"
              withinPortal
            >
              <HoverCard.Target>
                <Link className={classes.link}>
                  <Center inline>
                    <Box component="span" mr={5}>
                      Fonctionalités
                    </Box>
                    <IconChevronDown size={16} color={"#a1974b"} />
                  </Center>
                </Link>
              </HoverCard.Target>

              <HoverCard.Dropdown
                c="#fff"
                bg="#242424"
                sx={{ overflow: "hidden" }}
                maw={180}
              >
                <Group position="apart"></Group>

                <SimpleGrid c="#fff" cols={1} spacing={0}>
                  {links}
                </SimpleGrid>
              </HoverCard.Dropdown>
            </HoverCard>
            <Link to="/howItWorks" className={classes.link}>
              Comment ça marche ?
            </Link>
          </Group>
          <div className={classes.hiddenMobile}>
            {isAuth ? (
              <Link mr={50} to="/profile" className="header-login">
                Mon Compte
              </Link>
            ) : (
              <Link mr={50} to="/login" className="header-login">
                Se connecter
              </Link>
            )}
          </div>

          <Burger
            color="#fff"
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
          />
        </Group>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        zIndex={1000000}
      >
        <ScrollArea
          h={`calc(100vh - ${rem(60)})`}
          mx="-md"
          style={{
            backgroundImage:
              "linear-gradient(to top, #232121e0 0%, #181818 100%)",
          }}
        >
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />
          <Link className={classes.link} to="/profile">
            {isAuth ? "Mon compte" : "Se connecter"}
          </Link>

          <Link to="/" className={classes.link}>
            Accueil
          </Link>
          <UnstyledButton onClick={toggleLinks}>
            <Center inline>
              <Link className={classes.link} to="/Features">
                Fonctionalité
              </Link>
              <IconChevronDown size={16} color={"#a1974b"} />
            </Center>
          </UnstyledButton>
          <Collapse in={linksOpened}>{links}</Collapse>

          <Link to="/howItWorks" className={classes.link}>
            Comment ça marche ?
          </Link>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
