// index.js
import React from "react";
import { Container, Title, Paper, Divider } from "@mantine/core";
import DesignComponent from "./UI/DesignComponent";

const DesignCard = () => {
  return (
    <Container size="sm">
      <Paper shadow="sm" padding="lg" style={{ marginBottom: "20px" }}>
        <Title order={1}>Choisissez le design de votre carte de visite</Title>
        <Divider margin={{ top: "xs", bottom: "sm" }} />

        <p style={{ marginBottom: "20px" }}>
          Sélectionnez le design parfait pour votre carte de visite parmi les
          options ci-dessous
        </p>

        <DesignComponent />
      </Paper>
    </Container>
  );
};

export default DesignCard;
