import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import bcAPI from "./businessCardAPI";

const initialState = {
  isSucess: false,
  isLoading: false,
  message: "",
  bc: localStorage.getItem("bc") !== null ? localStorage.getItem("bc") : null,
  tempBCInfo: null,
  tempBCSocial: null,
  addBcToProfile: "",
  isLoadingBc: false,
  isSucessBc: false,
  portfeuille: null,
};

// create

export const Create = createAsyncThunk(
  "bc/Create",
  async ({ data }, thunkAPI) => {
    const token = localStorage.getItem("token");
    try {
      return await bcAPI.create(data, token);
    } catch (error) {
      const message = error.toString() || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// update

export const Update = createAsyncThunk(
  "bc/Update",
  async ({ data, token }, thunkAPI) => {
    try {
      return await bcAPI.update(data, token);
    } catch (error) {
      const message = error.toString() || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// getOne

export const GetOne = createAsyncThunk("bc/GetOne", async (token, thunkAPI) => {
  try {
    return await bcAPI.getOne(token);
  } catch (error) {
    const message = error.toString() || error.message;
    return thunkAPI.rejectWithValue(message);
  }
});

// add bc to profile

export const AddBusinessToProfile = createAsyncThunk(
  "bc/AddBusinessToProfile",
  async ({ businessCardId }, thunkAPI) => {
    const token = localStorage.getItem("token");
    try {
      return await bcAPI.addBusinessCardToProfile(businessCardId, token);
    } catch (error) {
      const message = error.toString() || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get portfeuille

export const GetPortfeuille = createAsyncThunk(
  "bc/GetPortfeuille",
  async (token, thunkAPI) => {
    try {
      return await bcAPI.getPortfeuille(token);
    } catch (error) {
      const message = error.toString() || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const bcSlice = createSlice({
  name: "bc",
  initialState,
  reducers: {
    reset: (state) => initialState,
    updateTempBCInfo: (state, action) => {
      state.tempBCInfo = action.payload;
    },
    updateTempBCSocial: (state, action) => {
      state.tempBCSocial = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(Create.pending, (state) => {
        state.isLoading = true;
        state.isSucess = false;
      })
      .addCase(Create.fulfilled, (state, action) => {
        state.isSucess = true;
        state.isLoading = false;
        state.bc = action.payload;
        localStorage.setItem("bc", action.payload);
      })
      .addCase(Create.rejected, (state, action) => {
        state.isSucess = false;
        state.message = action.error.message;
      })
      .addCase(Update.pending, (state) => {
        state.isLoading = true;
        state.isSucess = false;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.isSucess = true;
        state.isLoading = false;
        state.bc = action.payload;
        localStorage.setItem("bc", action.payload);
      })
      .addCase(Update.rejected, (state, action) => {
        state.isSucess = false;
        state.isLoading = false;
        state.message = action.error.message;
      })
      .addCase(GetOne.pending, (state) => {
        state.isLoading = true;
        state.isSucess = false;
      })
      .addCase(GetOne.fulfilled, (state, action) => {
        state.isSucess = true;
        state.isLoading = false;
        state.bc = action.payload;
        localStorage.setItem("bc", JSON.stringify(action.payload));
      })
      .addCase(GetOne.rejected, (state, action) => {
        state.isSucess = false;
        state.isLoading = false;
        state.message = action.error.message;
      })

      .addCase(AddBusinessToProfile.pending, (state) => {
        state.isLoadingBc = true;
        state.isSucessBc = false;
      })
      .addCase(AddBusinessToProfile.fulfilled, (state, action) => {
        state.isSucessBc = true;
        state.isLoadingBc = false;
        state.addBcToProfile = action.payload;
      })
      .addCase(AddBusinessToProfile.rejected, (state, action) => {
        state.isSucessBc = false;
        state.message = action.payload;
        // state.addBcToProfile = action.payload;
      })

      .addCase(GetPortfeuille.pending, (state) => {
        state.isLoadingBc = true;
        state.isSucessBc = false;
      })
      .addCase(GetPortfeuille.fulfilled, (state, action) => {
        state.isSucessBc = true;
        state.isLoadingBc = false;
        state.portfeuille = action.payload;
      })
      .addCase(GetPortfeuille.rejected, (state, action) => {
        state.isSucessBc = false;
        state.message = action.payload;
        // state.addBcToProfile = action.payload;
      });
  },
});

export const { reset, updateTempBCInfo, updateTempBCSocial } = bcSlice.actions;
export default bcSlice.reducer;
