import { useState } from "react";
import { Container, Paper, Stepper, Group, Button } from "@mantine/core";
import { Register } from "../../pages/Register";
import BusinessInfo from "./components/BusinessInfo";

import LastStepp from "./components/ConfirmationStep";
const initialUserData = {
  nom: "",
  prénom: "",
  email: "",
  job: "",
  company: "",
  phone: "",
  address: "",
  bio: "",
  twitter: "",
  facebook: "",
  linkedin: "",
  instagram: "",
};

function StepperRegister(props) {
  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));
  const [userData, setUserData] = useState(initialUserData);

  const handleFirstStepValidate = (data) => {
    setUserData((prev) => ({ ...prev, ...data }));
    nextStep();
  };

  const handleSecondStepValidate = (data) => {
    setUserData((prev) => ({ ...prev, ...data }));
    nextStep();
  };

  return (
    <Container size={1080} my={40}>
      <Paper radius="md" p="xl" withBorder {...props}>
        <Stepper active={active} onStepClick={setActive}>
          <Stepper.Step
            label="Creation du compte"
            description="Creation du compte"
          >
            <Register
              onValidate={handleFirstStepValidate}
              userData={userData}
              prevStep={prevStep}
            />
          </Stepper.Step>
          <Stepper.Step
            label="Business Card"
            description="Information supplémentaire"
          >
            <BusinessInfo
              onValidate={handleSecondStepValidate}
              userData={userData}
              prevStep={prevStep}
            />
          </Stepper.Step>
          <Stepper.Step label="Confirmation" description="">
            <LastStepp userData={userData} prevStep={prevStep} />
          </Stepper.Step>
          <Stepper.Completed>
            Completed, click back button to get to previous step
          </Stepper.Completed>
        </Stepper>

        {/* <Group justify="center" mt="xl">
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
          <Button onClick={nextStep}>Next step</Button>
        </Group> */}
      </Paper>
    </Container>
  );
}

export default StepperRegister;
