import { Text, Group, createStyles, TextInput, Textarea } from "@mantine/core";
import "../../../assets/css/Profile.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateTempBCInfo } from "../../../features/businessCard/businessSlice";

const useStyles = createStyles((theme) => ({
  wrapper: {
    justifyContent: "space-between",
    alignItems: "center",
    background: "#b5a46a",
    padding: "2rem ",

    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "0",
    },
  },

  flex: {
    alignItems: "center",
    marginBottom: "1rem",
  },

  input: {
    marginBottom: "1rem",
    input: {
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "1px solid rgb(224, 224, 224)",
      borderRadius: "0",
      width: "100%",
      "&:focus": {
        borderColor: "#bcaf4b",
      },
    },

    label: {
      color: "#c5c7c9",
      fontWeight: "400",
    },
  },
}));

export default function ContactInfo({ onSave, onSaveClick }) {
  const { classes } = useStyles();
  const [contactInfoData, setContactInfoData] = useState({});
  const dispatch = useDispatch();
  //events
  const handleInputChange = (event) => {
    setContactInfoData({
      ...contactInfoData,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    dispatch(updateTempBCInfo(contactInfoData));
  }, [contactInfoData]);

  return (
    <>
      <Group position="apart" mb="xs">
        <Text mb="1rem" weight={500}>
          À-propos
        </Text>
      </Group>
      <Textarea
        className="text-area"
        size="sm"
        color="dimmed"
        required
        placeholder="business details"
        name="business_details"
        value={contactInfoData.business_details}
        onChange={handleInputChange}
      />

      <Group display="block" position="apart" pt="2rem" mb="xs">
        <Text mb="1rem" weight={500}>
          Contact
        </Text>

        <TextInput
          className={classes.input}
          label="Nom"
          size="sm"
          required
          name="business_name"
          onChange={handleInputChange}
          color="dimmed"
          value={contactInfoData.business_name}
        />
        <TextInput
          className={classes.input}
          label="business address"
          size="sm"
          required
          name={"business_adress"}
          onChange={handleInputChange}
          color="dimmed"
          value={contactInfoData.business_adress}
        />
        <TextInput
          className={classes.input}
          label="Email"
          size="sm"
          required
          onChange={handleInputChange}
          name="email_adress"
          color="dimmed"
          value={contactInfoData.email_adress}
        />
        <TextInput
          className={classes.input}
          label="Téléphone"
          size="sm"
          onChange={handleInputChange}
          required
          name="phone_number"
          color="dimmed"
          value={contactInfoData.phone_number}
        />
        {/* <TextInput
          className={classes.input}
          label="Site Web"
          onChange={handleInputChange}
          size="sm"
          required
          color="dimmed"
          value={contactInfoData.website}
        /> */}
      </Group>
    </>
  );
}
