import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Group,
  Button,
  TextInput,
  Textarea,
  FileInput,
  Input,
  Checkbox,
  useMantineTheme,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandInstagram,
  IconCheck,
} from "@tabler/icons-react";
import { Create } from "../../../features/businessCard/businessSlice";

const socialMediaIcons = {
  twitter: IconBrandTwitter,
  facebook: IconBrandFacebook,
  linkedin: IconBrandLinkedin,
  instagram: IconBrandInstagram,
};

function ModalBusinessCard({ open, userData, callBack }) {
  const dispatch = useDispatch();
  const [opened, { open: openModal, close: closeModal }] = useDisclosure(open);

  const [formData, setFormData] = useState({
    userName: userData?.nom + userData?.prénom || "",
    email: userData?.email || "",
    phone: "",
    address: "",
    bio: "",
    company: "",
    job: "",
    socialData: {},
  });

  const [selectedSocialMedia, setSelectedSocialMedia] = useState({
    twitter: false,
    facebook: false,
    linkedin: false,
    instagram: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSocialInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      socialData: {
        ...prevFormData.socialData,
        [name]: value,
      },
    }));
  };

  const handleSocialCheckboxChange = (social) => {
    setSelectedSocialMedia((prevSelected) => ({
      ...prevSelected,
      [social]: !prevSelected[social],
    }));
  };

  const handleSubmit = () => {
    dispatch(
      Create({
        data: {
          business_name: formData.userName,
          email_adress: formData.email,
          phone_number: formData.phone,
          business_adress: formData.address,
          business_details: formData.bio,
          social_media_accounts: formData.socialData,
          profession: formData.job,
          societe: formData.company,
        },
      })
    );
    callBack(true);
  };

  const theme = useMantineTheme();

  return (
    <Modal
      title="Ajoutez les informations de votre carte de visite"
      opened={opened}
      onClose={closeModal}
      centered
    >
      <FileInput placeholder="Ajouter votre photo" />
      <Group grow>
        <TextInput
          name="userName"
          label="Prénom et Nom"
          placeholder="Prénom et Nom"
          value={formData.userName}
          onChange={handleInputChange}
          withAsterisk
        />
        <TextInput
          name="email"
          label="Adresse e-mail"
          placeholder="Adresse e-mail"
          value={formData.email}
          onChange={handleInputChange}
          withAsterisk
        />
      </Group>
      <Group grow>
        <TextInput
          name="job"
          label="Fonction"
          placeholder="Fonction"
          value={formData.job}
          onChange={handleInputChange}
          withAsterisk
        />
        <TextInput
          name="company"
          label="Société"
          placeholder="Société"
          value={formData.company}
          onChange={handleInputChange}
          withAsterisk
        />
      </Group>
      <TextInput
        name="phone"
        label="Numéro de téléphone"
        placeholder="+212 "
        value={formData.phone}
        onChange={handleInputChange}
        withAsterisk
      />
      <TextInput
        name="address"
        label="Adresse"
        placeholder="Adresse"
        value={formData.address}
        onChange={handleInputChange}
        withAsterisk
      />
      <Textarea
        name="bio"
        label="Biographie"
        placeholder="Biographie"
        value={formData.bio}
        onChange={handleInputChange}
        withAsterisk
      />

      <Input.Wrapper label="Remplissez vos comptes de réseaux sociaux" required>
        <Group>
          {Object.keys(socialMediaIcons).map((social) => {
            const Icon = socialMediaIcons[social];
            return (
              <Group key={social}>
                <Checkbox
                  icon={() => <Icon size={20} />}
                  checked={selectedSocialMedia[social]}
                  onChange={() => handleSocialCheckboxChange(social)}
                />
                {selectedSocialMedia[social] && (
                  <Input
                    name={social}
                    icon={<Icon size="1rem" />}
                    placeholder={`Votre ${social}`}
                    onChange={handleSocialInputChange}
                  />
                )}
              </Group>
            );
          })}
        </Group>
      </Input.Wrapper>

      <Group position="right" mt="md">
        <Button
          variant="gradient"
          gradient={{ from: "blue", to: "cyan", deg: 90 }}
          onClick={handleSubmit}
        >
          Valider
        </Button>
      </Group>
    </Modal>
  );
}

export default ModalBusinessCard;
