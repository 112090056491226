import axios from "axios";
import { API_HOST } from "../../utils/API";

const login = async ({ email, password }) => {
  const response = await axios.post(`${API_HOST}/auth/login`, {
    email: email,
    password,
  });
  return response.data;
};

const register = async ({
  step_1,
  step_2,
  job,
  company,
  phone,
  address,
  bio,
  socialData,
  email,
  password,
  nom,
  prénom,
  notebook_id,
}) => {
  const response = await axios.post(
    `${API_HOST}/auth/register${notebook_id ? `?id=${notebook_id}` : "/"}`,
    {
      step_1,
      step_2,
      job,
      company,
      phone,
      address,
      bio,
      socialData,
      email,
      password,
      first_name: prénom,
      family_name: nom,
    }
  );
  return response.data;
};

const getProfile = async (token) => {
  const response = await axios.get(`${API_HOST}/account/user`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const sendResetCode = async ({ email }) => {
  const response = await axios.post(`${API_HOST}/auth/forgot-password`, {
    email,
  });
  return response.data;
};

const resetPassword = async ({ email, resetCode, newPassword }) => {
  const response = await axios.post(`${API_HOST}/auth/reset-password`, {
    email,
    resetCode,
    newPassword,
  });
  return response.data;
};

const linkNotebookToUser = async ({ user_id, notebook_id, token }) => {
  const response = await axios.post(
    `${API_HOST}/account/connectnotebook`,
    { user_id, notebook_id },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const authAPI = {
  login,
  register,
  getProfile,
  sendResetCode,
  resetPassword,
  linkNotebookToUser,
};
export default authAPI;
