import React, { useEffect, useState } from "react";
import { Grid, Text } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/Profile.css";
import { GetPortfeuille } from "../../../features/businessCard/businessSlice";
import { BeatLoader } from "react-spinners";
import BusinessCardPortfeuille from "./BusinessCardPortfeuille";

export default function Portefeuille() {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const { portfeuille, isLoadingBc } = useSelector((state) => state.bc);

  useEffect(() => {
    if (token) {
      dispatch(GetPortfeuille(token));
    }
  }, [dispatch]);

  if (isLoadingBc) {
    return (
      <div className="qrc_page_wrapper custom_spinner">
        <BeatLoader color="#36d7b7" />
      </div>
    );
  }
  return (
    <Grid
      gutter="xs"
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      {!Array.isArray(portfeuille?.businessCards) ||
      portfeuille?.businessCards.length === 0 ? (
        <Text
          style={{
            color: "grey",
            fontSize: "13px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            textAlign: "center",
          }}
        >
          Aucune carte de visite n'a encore été ajoutée à votre portefeuille.
        </Text>
      ) : (
        portfeuille?.businessCards.map((data) => (
          <BusinessCardPortfeuille data={data} />
        ))
      )}
    </Grid>
  );
}
