import { Container } from "@mantine/core";
import Header from "../BusCard/Components/Header";
import ContactSection from "../BusCard/Components/ContactSection";
import SocialMedia from "../BusCard/Components/SocialMedia";

function BusCard() {
  return (
    <Container
      fluid
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        backgroundColor: "rgba(232, 230, 230, 1)",
      }}
    >
      <Header />
      <ContactSection />
      <SocialMedia />
    </Container>
  );
}
export default BusCard;
