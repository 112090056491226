import React from "react";
import "../../assets/css/Custom.css";
import {
  FaUpload,
  FaPlus,
  FaPhoneAlt,
  FaRegEnvelope,
  FaRegEdit,
} from "react-icons/fa";
import myImage from "../../assets/img/profile_logo.png";
import SocialMedia from "../../scan_side/SocialMedia";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
} from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Center, Loader } from "@mantine/core";
import HeaderBusinessCard from "../../BusCard/Components/Header";
import ContactSectionBusiness from "../../BusCard/Components/ContactSection";
import SocialMediaBusiness from "../../BusCard/Components/SocialMedia";

const socialMediaIcons = {
  facebook: IconBrandFacebook,
  twitter: IconBrandTwitter,
  linkedin: IconBrandLinkedin,
  instagram: IconBrandInstagram,
};

export default function BusinessCard() {
  const { bc, isLoading } = useSelector((state) => state.bc);

  const handleDownload = () => {
    const vcfData = `
      BEGIN:VCARD
      VERSION:3.0
      FN:${bc?.business_name || ""}
      TEL:${bc?.phone_number || ""}
      EMAIL:${bc?.email_adress || ""}
      END:VCARD
    `;

    const blob = new Blob([vcfData], { type: "text/vcard;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${bc?.business_name || "Contact"}Contacts.vcf`;

    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    a.dispatchEvent(event);

    window.URL.revokeObjectURL(url);
  };

  if (isLoading) {
    return (
      <Center h={400}>
        <Loader color="blue" />
      </Center>
    );
  }

  const socialMediaAccounts = bc?.social_media_accounts || {};

  return (
    <div style={{ border: "1px solid black" }} className="responsive_card">
      <HeaderBusinessCard data={bc} />
      <ContactSectionBusiness data={bc} />
      <SocialMediaBusiness data={bc} />
    </div>
  );
}
