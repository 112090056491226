import { Container, Flex, Title, Text, Box } from "@mantine/core";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mantine/hooks";

function ContactSection({ data }) {
  const extraSmall = useMediaQuery("(max-width: 576px)");
  const smallScreen = useMediaQuery(
    "(min-width: 577px) and (max-width: 768px)"
  );
  const mediumScreen = useMediaQuery(
    "(min-width: 768px) and (max-width: 992px)"
  );

  const containerWidth = extraSmall
    ? "15%"
    : smallScreen
    ? "32%"
    : mediumScreen
    ? "35%"
    : "37%";
  const hrWidth = extraSmall
    ? 290
    : smallScreen
    ? 390
    : mediumScreen
    ? 400
    : 410;

  return (
    <Container style={{ width: containerWidth, paddingLeft: "35px" }}>
      <Flex
        w={300}
        gap={10}
        justify="center"
        align="flex-start"
        direction="column"
        wrap="wrap"
        mt={20}
        ml={-140}
      >
        <Title ml={10} pb={3} pt={5} order={2} mt={-3}>
          {" "}
          Coordonnées
        </Title>
        {/* Phone Number */}
        <Box display={"flex"}>
          <FontAwesomeIcon
            icon={faPhone}
            style={{
              color: "black",
              fontSize: "24px",
              marginRight: "30px",
              marginLeft: "10px",
            }}
          />
          <Text>{data?.phone_number}</Text>
        </Box>
        <hr
          style={{
            width: hrWidth,
            // maxWidth: "500px",
            border: "0.1px solid grey",
            marginTop: "1px",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
        ></hr>
        {/* Email */}
        <Box display={"flex"}>
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{
              color: "black",
              fontSize: "24px",
              marginRight: "30px",
              marginLeft: "10px",
            }}
          />
          <Text>{data?.email_adress}</Text>
        </Box>
        <hr
          style={{
            width: hrWidth,
            border: "0.1px solid grey",
            marginTop: "2px",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
        ></hr>
        {/* Localisation */}
        <Box display={"flex"}>
          <FontAwesomeIcon
            icon={faLocationDot}
            style={{
              color: "black",
              fontSize: "24px",
              marginRight: "30px",
              marginLeft: "10px",
            }}
          />
          <Text size="md">{data?.business_adress}</Text>
        </Box>
        <hr
          style={{
            width: hrWidth,
            border: "0.1px solid grey",
            marginTop: "2px",
            marginLeft: "10px",
          }}
        ></hr>
      </Flex>
    </Container>
  );
}

export default ContactSection;
