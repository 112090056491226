// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Register-wrapper .mantine-Paper-root {
  background-color: transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.093);
  border: 1px solid rgba(72, 72, 72, 0.382);
  border-radius: 10px;
}

.mantine-ucxx48 {
  background-color: transparent;
}

.mantine-121k94u {
  padding: 0;
}

.mantine-r1l2de {
  color: #a1974b;
}

.form-flex .mantine-InputWrapper-label {
  position: absolute;
  left: -9999px;
}

form p {
  color: #828282;
  font-size: 0.85em;
  text-align: center;
  margin: 0.9rem;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Register.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,6CAA6C;EAC7C,yCAAyC;EACzC,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".Register-wrapper .mantine-Paper-root {\n  background-color: transparent;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.093);\n  border: 1px solid rgba(72, 72, 72, 0.382);\n  border-radius: 10px;\n}\n\n.mantine-ucxx48 {\n  background-color: transparent;\n}\n\n.mantine-121k94u {\n  padding: 0;\n}\n\n.mantine-r1l2de {\n  color: #a1974b;\n}\n\n.form-flex .mantine-InputWrapper-label {\n  position: absolute;\n  left: -9999px;\n}\n\nform p {\n  color: #828282;\n  font-size: 0.85em;\n  text-align: center;\n  margin: 0.9rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
