import axios from "axios";
import { API_HOST } from "../../utils/API";

const home = async () => {
  const response = await axios.get(`${API_HOST}/home`, {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
  return response.data;
};

const homePageData = { home };
export default homePageData;
