// DesignOption.js
import React from "react";
import { Image, Col, Center } from "@mantine/core";

const DesignOption = ({ id, name, image, onSelect, isSelected }) => {
  return (
    <Center>
      <Col span={4} className="design-option">
        <div className="design-option-content">
          <Image src={image} alt={name} className="design-option-image" />
          <label className="design-option-label">
            <input
              type="radio"
              name="designOptions"
              value={id}
              onChange={() => onSelect(id)}
              checked={isSelected}
            />
            {name}
          </label>
        </div>
      </Col>
    </Center>
  );
};

export default DesignOption;
