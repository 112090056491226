import axios from "axios";
import { API_HOST } from "../../utils/API";

const create = async (data, token) => {
  const response = await axios.post(
    `${API_HOST}/bc_customization/create`,
    data,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const update = async (data, token) => {
  const response = await axios.put(
    `${API_HOST}/bc_customization/update`,
    data,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const getOne = async (token) => {
  const response = await axios.get(
    `${API_HOST}/bc_customization/businesscard`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const addBusinessCardToProfile = async (businessCardId, token) => {
  const response = await axios.post(
    `${API_HOST}/dashboard/add-business-card`,
    { businessCardId: businessCardId },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const getPortfeuille = async (token) => {
  const response = await axios.get(`${API_HOST}/dashboard/get-portfeuille`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const bcAPI = {
  create,
  update,
  getOne,
  addBusinessCardToProfile,
  getPortfeuille,
};
export default bcAPI;
