import React, { useState, useEffect } from "react";
import { Modal, Button, Image, Text, Box } from "@mantine/core";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { linkNotebookToUser } from "../../../features/auth/authSlice";

const NotebookLinkPopup = ({
  onLinkNotebook,
  image,
  onClose,
  userID,
  notebookID,
}) => {
  const [opened, setOpened] = useState(true);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const { linkNotebook, isLoadingLink, isSuccessLink } = useSelector(
    (state) => state.auth
  );

  // Link notebook function
  const handleLinkNotebook = async () => {
    try {
      await dispatch(
        linkNotebookToUser({
          user_id: userID,
          notebook_id: notebookID,
        })
      );

      onLinkNotebook(); // Call parent onClick if needed
    } catch (error) {
      setMessage(error.linkNotebookz?.message || "Error linking notebook.");
    }
    // alert("hello");
  };

  useEffect(() => {
    if (notebookID) setOpened(true); // Open popup if notebook_id exists
  }, [notebookID]);

  useEffect(() => {
    if (isSuccessLink) {
      setMessage(linkNotebook?.message || "Notebook linked successfully!");
    }
  }, [isLoadingLink]);

  return (
    <Modal
      opened={opened}
      onClose={() => {
        setOpened(false);
        onClose();
      }}
      title="Lier le Notebook au compte"
      centered
    >
      <Box
        style={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Image src={image} alt="Notebook" width={100} height={100} mb="sm" />
        <Text>
          {message || "Souhaitez-vous lier ce Notebook à votre compte ?"}
        </Text>
        {!message && (
          <Button color="teal" mt="lg" onClick={handleLinkNotebook}>
            {isLoadingLink ? "..." : "Confirmer"}
          </Button>
        )}
      </Box>
    </Modal>
  );
};

export default NotebookLinkPopup;
