import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import homePageData from "./homeApi";

const initialState = {
  isSucess: false,
  isLoading: false,
  data:
    localStorage.getItem("data") === null
      ? ""
      : JSON.parse(localStorage.getItem("data")),
  message: {},
};

export const Home = createAsyncThunk("home/getData", async (thunkAPI) => {
  try {
    return await homePageData.home();
  } catch (error) {
    const message = error.toString() || error.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(Home.pending, (state) => {
        state.pending = true;
      })
      .addCase(Home.fulfilled, (state, action) => {
        state.isSucess = true;
        state.isLoading = false;
        state.data = action.payload;
        localStorage.setItem("data", JSON.stringify(action.payload));
      })
      .addCase(Home.rejected, (state, action) => {
        state.isSucess = false;
        state.isLoading = false;
        state.message = action.payload;
      });
  },
});

export const { reset } = homeSlice.actions;
export default homeSlice.reducer;
