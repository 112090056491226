// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mantine-r9tegj {
  background-color: #181818;
  max-height: 5rem;
  height: 4.9rem;
  color: white;

  border: none;
}

.mantine-1grbdle {
  padding-bottom: 0;
}

.mantine-1pmmtd9 {
  color: white;
  font-size: 0.9em;
  line-height: 20px;
}

.mantine-1pmmtd9:hover {
  background-color: rgba(69, 69, 69, 0.288);
  color: #fff;
}

.header-logo {
  margin-left: 3rem;
  font-size: 25px;
  font-weight: 500;
}

.mantine-dhrao7 {
  color: #fff;

  transition: background-color 0.5s;
}

.mantine-dhrao7:hover {
  background-color: #cdcdcd25;
}

.header-login {
  font-weight: 400;
  border: 1px solid #fff;
  padding: 10px 15px;
  font-size: 0.9rem;
  border-radius: 8px;
  text-decoration: none;
  color: #fff;
  margin-right: 3rem;
  transition: border-color 0.5s, color 0.5s;
  background-color: transparent;
}

.header-login:hover {
  background-color: transparent;
  color: #ffffffcc;
  cursor: pointer;
  border-color: #ffffffcc;
}

.mantine-pkjy5x {
  border-color: #9e9c9c79;
}

.mantine-qsi4hl {
  color: #fff;
}

.mantine-qsi4hl:hover {
  background-color: rgba(69, 69, 69, 0.288);
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;EACd,YAAY;;EAEZ,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,yCAAyC;EACzC,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;;EAEX,iCAAiC;AACnC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,kBAAkB;EAClB,yCAAyC;EACzC,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yCAAyC;EACzC,WAAW;AACb","sourcesContent":[".mantine-r9tegj {\n  background-color: #181818;\n  max-height: 5rem;\n  height: 4.9rem;\n  color: white;\n\n  border: none;\n}\n\n.mantine-1grbdle {\n  padding-bottom: 0;\n}\n\n.mantine-1pmmtd9 {\n  color: white;\n  font-size: 0.9em;\n  line-height: 20px;\n}\n\n.mantine-1pmmtd9:hover {\n  background-color: rgba(69, 69, 69, 0.288);\n  color: #fff;\n}\n\n.header-logo {\n  margin-left: 3rem;\n  font-size: 25px;\n  font-weight: 500;\n}\n\n.mantine-dhrao7 {\n  color: #fff;\n\n  transition: background-color 0.5s;\n}\n\n.mantine-dhrao7:hover {\n  background-color: #cdcdcd25;\n}\n\n.header-login {\n  font-weight: 400;\n  border: 1px solid #fff;\n  padding: 10px 15px;\n  font-size: 0.9rem;\n  border-radius: 8px;\n  text-decoration: none;\n  color: #fff;\n  margin-right: 3rem;\n  transition: border-color 0.5s, color 0.5s;\n  background-color: transparent;\n}\n\n.header-login:hover {\n  background-color: transparent;\n  color: #ffffffcc;\n  cursor: pointer;\n  border-color: #ffffffcc;\n}\n\n.mantine-pkjy5x {\n  border-color: #9e9c9c79;\n}\n\n.mantine-qsi4hl {\n  color: #fff;\n}\n\n.mantine-qsi4hl:hover {\n  background-color: rgba(69, 69, 69, 0.288);\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
