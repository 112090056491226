import { Container, Title, Text, createStyles, rem } from "@mantine/core";
import "../assets/css/FaqPage.css";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    minHeight: 650,
  },

  title: {
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export function Terms() {
  const { classes } = useStyles();
  return (
    <Container size="55rem" className={`${classes.wrapper} page-wrapper`}>
      <Title mb="4rem" align="center" className={classes.title}>
        Conditions générales d'utilisation
      </Title>

      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        1. Identité
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        Maroc Soir, SA au capital de 137 000 000 DHS, immatriculée au Registre
        du commerce de Casablanca, sous le N° 31427, dont le siège social est
        situé au 17, Rue Othman Ben Affan - CASABLANCA – MAROC, représentée par
        Monsieur Mohammed Haitami (ci-après « MAROC SOIR»), en qualité de
        Président-Directeur Général, édite un site d'informations générales
        www.lematin.ma, ci-après le « Site » permettant un accès à différentes
        informations, ainsi qu'à différents services et produits
        complémentaires, détaillés ci-après Les présentes conditions générales
        d'utilisation, ("CGU"), soumises au droit marocain, ont vocation à régir
        l'utilisation du Site Internet ainsi que l'utilisation des différents
        services payants ou gratuits. La navigation dans le Site et/ou
        l'inscription à un service et/ou la commande d’un produit, emporte de
        plein droit acceptation expresse et sans réserve des CGU par
        l'utilisateur du Site. En cas de non respect des termes des présentes
        CGU, MAROC SOIR se réserve le droit de prendre toute mesure de nature à
        préserver ses intérêts et notamment à en assurer l'exécution. MAROC SOIR
        pourra notamment à cette fin assigner l'utilisateur du Site en
        responsabilité civile et/ou pénale. Toute condition contraire non
        acceptée par MAROC SOIR lui est inopposable. Le fait pour MAROC SOIR de
        ne pas se prévaloir d'une des présentes conditions générales n'équivaut
        pas à une renonciation de MAROC SOIR à s'en prévaloir ultérieurement.
      </Text>

      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        2. Déclaration
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        Les données à caractère personnel recueillies dans le cadre de ce site
        ont fait l'objet d'une déclaration à la Commission Nationale de Contrôle
        des Données à caractère Personnel (la CNDP). Les utilisateurs peuvent
        obtenir à cet effet les renseignements nécessaires auprès de cet
        organisme.
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        3. Réservation du nom de domaine
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        www.innermind.ma, est un domaine enregistré et réservé (ci-après « le
        Site Internet »).
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        4. Avertissement aux utilisateurs
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        MAROC SOIR offre aux utilisateurs l'accès au présent Site Internet
        conformément aux conditions générales d’utilisation faisant l’objet des
        présentes. Les utilisateurs s'engagent à s'y conformer et à les
        respecter.
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        5. Utilisation du Site
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        MAROC SOIR a créé le Site Internet LEMATIN.MA qui emploie ses meilleurs
        moyens pour s'assurer de l'exactitude et la mise à jour des informations
        diffusées sur le Site Internet dont elle se réserve le droit, à tout
        moment et à son entière discrétion, de corriger le contenu.
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        6. Présentation du Site
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        Le Site propose notamment à l'Utilisateur :
        <ul>
          <li>
            {" "}
            la consultation de contenus et d'informations présents sur le Site
            (moyennant, le cas échéant, la souscription d’un abonnement pour les
            contenus payants)
          </li>
          <li>
            {" "}
            différents Services lui permettant d'avoir accès à un contenu
            spécifique et particulier et/ou condensé de l'actualité du jour, de
            mettre en ligne, de stocker, de partager et commenter les contenus
            personnels et collectifs et/ou de bénéficier de services et d'offres
            promotionnelles spécifiques).
          </li>
        </ul>
        Les Services regroupent notamment les services suivants :
        <ul>
          <li>
            {" "}
            des Services gratuits permettant à ses membres de disposer d'un
            espace personnel, et d'interagir avec les journalistes et éditeur du
            site (Mon Matin), ou d'accéder à un contenu spécifique et
            particulier.
          </li>
          <li>
            {" "}
            des Services payants proposant entre autre un accès aux versions
            E-Paper du quotidien Le Matin, des contenus réservés et/ou exclusifs
            et autres avantages.
          </li>
        </ul>
        <p>
          En tant qu'Utilisateur inscrit à un ou des Services, celui-ci peut
          disposer d'un espace personnel. Le Site n'étant cependant pas conçu
          pour la sauvegarde de contenus, il appartient à l'Utilisateur de
          prendre les mesures nécessaires à cet effet.
        </p>
        <p>
          L'Utilisateur est informé que l’accès libre et gratuit aux Contenus et
          Services du Site, ainsi qu’aux Applications (hors édition abonnés),
          est financé par les revenus liés à la publicité diffusée sur ces
          espaces.
        </p>
        <p>
          Il accepte par conséquent l’affichage de la publicité sur le Site et
          les Applications.
        </p>
        <p>
          En sa qualité d'éditeur du Site, Lematin.ma est libre de
          commercialiser auprès d'annonceurs de son choix les espaces
          publicitaires du Site. L'Utilisateur reconnaît ne disposer d'aucun
          droit sur ces espaces publicitaires et s'interdit toute demande ou
          action à ce titre.
        </p>
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        7. Accès au site
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        Le Site Internet permet un accès à différentes informations, ainsi qu'à
        différents services et produits. <strong>InnerMind</strong> ne saurait
        garantir que le fonctionnement du Site Internet sera ininterrompu.{" "}
        <strong>InnerMind</strong> n'est tenu que d'une obligation de moyen en
        ce qui concerne le fonctionnement et la continuité du service. De même
        sa responsabilité ne saurait être engagée pour des faits dus à un cas de
        force majeure. L'utilisation du présent site s'effectue exclusivement
        aux risques de l'utilisateur, aucune garantie d'aucune sorte, explicite
        ou implicite n'est consentie par <strong>InnerMind</strong> .
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        8. Informations disponibles sur le Site Internet
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        MAROC SOIR offre aux utilisateurs l'accès au présent Site Internet
        conformément aux conditions générales d’utilisation faisant l’objet des
        présentes. Les utilisateurs s'engagent à s'y conformer et à les
        respecter.
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        4. Avertissement aux utilisateurs
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        <p>
          Le Site Internet comporte des informations mises à disposition par des
          sociétés externes ou des liens hypertextes vers d’autres sites qui
          n’ont pas été développés par <strong>InnerMind</strong>. L'existence
          de liens du Site Internet vers d'autres sites ne constitue en rien une
          validation de ces sites ou de leur contenu par{" "}
          <strong>InnerMind</strong>.
        </p>
        <p>
          L'existence d'un lien de <strong>InnerMind</strong> vers un autre site
          ne constitue pas par conséquent, une recommandation ni une validation
          de ce site ou de son contenu. Il appartient à l'utilisateur d'utiliser
          ces informations avec discernement et esprit critique.
        </p>
        <p>
          Les utilisateurs et visiteurs du site au demeurant ne peuvent en aucun
          cas mettre en place un lien hypertexte en direction du site sans
          l'autorisation écrite et préalable de M <strong>InnerMind</strong>.
        </p>
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        10. Propriété Intellectuelle
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        <p>
          Le Site Internet de <strong>InnerMind</strong> est une œuvre de
          l'esprit protégée par les droits de propriété intellectuelle. Le Site
          Internet et chacun des éléments qui le composent tels que, notamment
          marques, textes, illustrations, logos, dénominations sociales,
          produits, photographies sont la propriété exclusive de{" "}
          <strong>InnerMind</strong>. La reproduction de tous documents publiés
          sur le Site Internet est seulement autorisée aux fins exclusives
          d'information pour un usage strictement personnel et privé.
        </p>
        <p>
          Conformément à la loi 09-08 sur la protection des personnes physiques
          à l'égard du traitement des données à caractère personnel,
          l'utilisateur dispose d'un droit d'accès, de rectification et
          d'opposition au traitement des données personnelles le concernant
          auprès de <strong>InnerMind</strong>.
        </p>
        <p>
          Compte tenu des risques liés à l'utilisation d'Internet, l'utilisateur
          est informé que les données le concernant peuvent circuler sur les
          réseaux sans garanties de sécurité et qu'elles risquent d'être lues et
          utilisées, par des tiers non autorisés.
        </p>
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        11. Données personnelles
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        <p>
          Les données saisies pour les services offerts par le site sont
          exclusivement destinées à l'usage interne de MAROC SOIR et à la
          gestion du site et des services proposés par le site. Toutefois, ces
          données pourront être confiées par MAROC SOIR à des partenaires
          (sous-traitants) pour la gestion du site et des services proposés.
        </p>
        <p>
          Conformément à la loi 09-08 sur la protection des personnes physiques
          à l'égard du traitement des données à caractère personnel,
          l'utilisateur dispose d'un droit d'accès, de rectification et
          d'opposition au traitement des données personnelles le concernant
          auprès de MAROC SOIR.
        </p>
        <p>
          Compte tenu des risques liés à l'utilisation d'Internet, l'utilisateur
          est informé que les données le concernant peuvent circuler sur les
          réseaux sans garanties de sécurité et qu'elles risquent d'être lues et
          utilisées, par des tiers non autorisés.
        </p>
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        12. Témoins de navigation (« cookies »)
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        <p>
          L'Utilisateur en vue de générer des statistiques sur l'utilisation du
          Site Internet (les pages les plus visitées, la fréquence d'accès au
          site, etc).
        </p>
        <p>
          Les Cookies émises permettent notamment, d’adapter la présentation de
          notre Site Internet aux préférences d'affichage de votre terminal
          (langue utilisée, résolution d'affichage, système d'exploitation
          utilisé, etc.) lors des visites de l’Utilisateur sur notre Site
          Internet, selon les matériels et les logiciels
        </p>
        <p>
          La durée de conservation des cookies n'excède pas la durée de la
          session en cours pour les données d'identification de la connexion.
        </p>
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        13. Modifications des conditions générales
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        Les présentes conditions générales sont consultables en ligne sur le
        site. Elles sont susceptibles d'être modifiées sans autre formalité que
        la mise en ligne d'une nouvelle version modifiée, seule cette dernière
        version étant retenue.
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        14. Renseignements – Réclamationss
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        Toute précision relative à l'application des présentes conditions
        générales, toute demande d'information ou réclamation relative au
        fonctionnement du Site Internet doit être adressée au service - clients:
        <ul>
          <li>
            Par voie postale: n°17 rue Othmane Ben Affane, Casablanca 20 070
          </li>
          <li>Par courriel : support@innermind.com</li>
        </ul>
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        15. Compétence juridictionnelle et loi applicable
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        Tout litige portant sur l'interprétation ou l'exécution des présentes
        sera soumis à la compétence exclusive du Tribunal de Commerce de
        Casablanca, la loi marocaine étant applicable au fond et à la procédure.
      </Text>

      <Title mb="3rem" size="23px">
        Mentions légales
      </Title>

      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        Editeur du Site :
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        Le Matin Numéro RC: 536459 Responsable editorial : contact@innermind.com
        17 Rue Othman Ben Affane, Ex Lafuente, Casablanca 20100 Téléphone : 06
        64 12 23 72. - Email : contact@innermind.com Site Web :
        www.innermind.com
      </Text>
      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        Développement :
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        société Le Matin Adresse : 17 Rue Othman Ben Affane, Ex Lafuente,
        Casablanca 20100 Site Web : www.innermind.com
      </Text>
    </Container>
  );
}
