// DesignComponent.js
import React, { useState } from "react";
import DesignOption from "./DesignOption";
import { Container, Grid, Col } from "@mantine/core";
import design_1 from "../../../assets/img/design-1.png";
import design_2 from "../../../assets/img/design-2.png";
import design_3 from "../../../assets/img/design-3.png";
const DesignComponent = () => {
  const designOptions = [
    { id: 1, name: "Design 1", image: design_1 },
    { id: 2, name: "Design 2", image: design_2 },
    { id: 3, name: "Design 3", image: design_3 },
    // Add more design options as needed
  ];

  const [selectedDesign, setSelectedDesign] = useState(null);

  const handleDesignSelect = (designId) => {
    setSelectedDesign(designId);
  };

  return (
    <Container size="sm" className="design-container">
      <Grid justify="space-between" className="design-grid">
        {designOptions.map((design) => (
          <Col span={4} key={design.id}>
            <DesignOption
              id={design.id}
              name={design.name}
              image={design.image}
              onSelect={handleDesignSelect}
              isSelected={selectedDesign === design.id}
            />
          </Col>
        ))}
      </Grid>
      {selectedDesign && (
        <div className="selected-design">
          <h3>Selected Design</h3>
          <img
            src={
              designOptions.find((design) => design.id === selectedDesign).image
            }
            alt={`Selected Design ${selectedDesign}`}
            className="selected-design-image"
          />
        </div>
      )}
    </Container>
  );
};

export default DesignComponent;
