import {
  createStyles,
  Title,
  SimpleGrid,
  Text,
  ThemeIcon,
  Grid,
  Col,
  rem,
} from "@mantine/core";
import {
  IconNews,
  IconWallet,
  IconChartBar,
  IconSocial,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: `calc(${theme.spacing.xl} * 2) ${theme.spacing.xl}`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(36),
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

const features = [
  {
    icon: IconNews,
    title: "Digitisation de notes",
    description:
      "Numérisez facilement vos notes manuscrites d'une manière organisée et facilement accessibles dans votre compte InnerMind.",
  },
  {
    icon: IconWallet,
    title: "Portefeuille",
    description:
      "Stocker toutes les cartes de visite des autres en un seul endroit pour une gestion facile",
  },
  {
    icon: IconChartBar,
    title: "La performance de votre carte",
    description:
      "Suivez l'efficacité de votre carte de visite électronique en temps réel pour mesurer l'impact de vos efforts de réseautage et ajuster votre stratégie en conséquence.",
  },
  {
    icon: IconSocial,
    title: "Intégration des Réseaux Sociaux ",
    description:
      "Développez votre réseau professionnel en vous connectant avec des personnes dans votre domaine d'activité.",
  },
];

export function Features() {
  const { classes } = useStyles();

  const items = features.map((feature) => (
    <div key={feature.title}>
      <ThemeIcon
        size={44}
        radius="md"
        variant="gradient"
        gradient={{ deg: 133, from: "blue", to: "cyan" }}
      >
        <feature.icon size={rem(26)} stroke={1.5} />
      </ThemeIcon>
      <Text fz="lg" mt="sm" fw={500}>
        {feature.title}
      </Text>
      <Text c="dimmed" fz="sm">
        {feature.description}
      </Text>
    </div>
  ));

  return (
    <div className={classes.wrapper}>
      <Grid gutter={80}>
        <Col span={12} md={5}>
          <Title className={classes.title} order={2}>
            InnerMind, le bloc-notes intelligent qui vous suit partout !
          </Title>
          <Text c="dimmed">
            Prenez des notes efficacement et numérisez-les facilement avec
            InnerMind, le bloc-notes innovant pour une gestion efficace de vos
            cartes de visite et de vos notes
          </Text>
        </Col>
        <Col span={12} md={7}>
          <SimpleGrid
            cols={2}
            spacing={30}
            breakpoints={[{ maxWidth: "md", cols: 1 }]}
          >
            {items}
          </SimpleGrid>
        </Col>
      </Grid>
    </div>
  );
}
