import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const Chart = () => {
  const data = [
    {
      time: "Janvier",
      "hot dog": 35,
      "hot dogColor": "hsl(235, 70%, 50%)",
      burger: 168,
      burgerColor: "hsl(119, 70%, 50%)",
      sandwich: 12,
      sandwichColor: "hsl(116, 70%, 50%)",
      kebab: 115,
      kebabColor: "hsl(234, 70%, 50%)",
      fries: 120,
      friesColor: "hsl(151, 70%, 50%)",
      donut: 109,
      donutColor: "hsl(298, 70%, 50%)",
    },

    {
      time: "Février",
      "hot dog": 10,
      "hot dogColor": "hsl(205, 70%, 50%)",
      burger: 52,
      burgerColor: "hsl(113, 70%, 50%)",
      sandwich: 59,
      sandwichColor: "hsl(349, 70%, 50%)",
      kebab: 100,
      kebabColor: "hsl(75, 70%, 50%)",
      fries: 126,
      friesColor: "hsl(281, 70%, 50%)",
      donut: 139,
      donutColor: "hsl(295, 70%, 50%)",
    },
    {
      time: "Mars",
      "hot dog": 71,
      "hot dogColor": "hsl(272, 70%, 50%)",
      burger: 94,
      burgerColor: "hsl(114, 70%, 50%)",
      sandwich: 20,
      sandwichColor: "hsl(122, 70%, 50%)",
      kebab: 127,
      kebabColor: "hsl(241, 70%, 50%)",
      fries: 196,
      friesColor: "hsl(290, 70%, 50%)",
      donut: 70,
      donutColor: "hsl(75, 70%, 50%)",
    },
    {
      time: "Avril",
      "hot dog": 193,
      "hot dogColor": "hsl(260, 70%, 50%)",
      burger: 116,
      burgerColor: "hsl(353, 70%, 50%)",
      sandwich: 182,
      sandwichColor: "hsl(134, 70%, 50%)",
      kebab: 177,
      kebabColor: "hsl(360, 70%, 50%)",
      fries: 9,
      friesColor: "hsl(290, 70%, 50%)",
      donut: 199,
      donutColor: "hsl(232, 70%, 50%)",
    },
    {
      time: "Mai",
      "hot dog": 69,
      "hot dogColor": "hsl(272, 70%, 50%)",
      burger: 104,
      burgerColor: "hsl(333, 70%, 50%)",
      sandwich: 172,
      sandwichColor: "hsl(298, 70%, 50%)",
      kebab: 121,
      kebabColor: "hsl(223, 70%, 50%)",
      fries: 180,
      friesColor: "hsl(155, 70%, 50%)",
      donut: 70,
      donutColor: "hsl(273, 70%, 50%)",
    },
    {
      time: "Juin",
      "hot dog": 199,
      "hot dogColor": "hsl(61, 70%, 50%)",
      burger: 57,
      burgerColor: "hsl(72, 70%, 50%)",
      sandwich: 161,
      sandwichColor: "hsl(183, 70%, 50%)",
      kebab: 169,
      kebabColor: "hsl(21, 70%, 50%)",
      fries: 109,
      friesColor: "hsl(338, 70%, 50%)",
      donut: 95,
      donutColor: "hsl(195, 70%, 50%)",
    },
    {
      time: "Juillet",
      "hot dog": 3,
      "hot dogColor": "hsl(215, 70%, 50%)",
      burger: 175,
      burgerColor: "hsl(43, 70%, 50%)",
      sandwich: 90,
      sandwichColor: "hsl(281, 70%, 50%)",
      kebab: 132,
      kebabColor: "hsl(354, 70%, 50%)",
      fries: 37,
      friesColor: "hsl(352, 70%, 50%)",
      donut: 179,
      donutColor: "hsl(102, 70%, 50%)",
    },
  ];

  return (
    <ResponsiveBar
      height={800}
      data={data}
      keys={["hot dog", "burger", "sandwich", "kebab", "fries", "donut"]}
      indexBy="time"
      margin={{ top: 230, right: 130, bottom: 170, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "time",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "food",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " in time: " + e.indexValue
      }
    />
  );
};

export default Chart;
