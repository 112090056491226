import { Container, Title, Text, createStyles, rem } from "@mantine/core";
import "../assets/css/FaqPage.css";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    minHeight: 650,
  },

  title: {
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export function Privacy() {
  const { classes } = useStyles();
  return (
    <Container size="55rem" className={`${classes.wrapper} page-wrapper`}>
      <Title mb="4rem" align="center" className={classes.title}>
        Confidentialité
      </Title>

      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        1. Lorem
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod
        dignissim diam, ut eleifend velit accumsan vel. Nam laoreet leo ac nunc
        iaculis, quis consequat mi sodales. Proin auctor lorem in ante
        tincidunt, ut facilisis risus bibendum.
      </Text>

      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        1. Lorem
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod
        dignissim diam, ut eleifend velit accumsan vel. Nam laoreet leo ac nunc
        iaculis, quis consequat mi sodales. Proin auctor lorem in ante
        tincidunt, ut facilisis risus bibendum.
      </Text>

      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        1. Lorem
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod
        dignissim diam, ut eleifend velit accumsan vel. Nam laoreet leo ac nunc
        iaculis, quis consequat mi sodales. Proin auctor lorem in ante
        tincidunt, ut facilisis risus bibendum.
      </Text>

      <Title mb="1rem" size="lg" className="conditions-utilisation-title">
        1. Lorem
      </Title>
      <Text mb="2rem" className="conditions-utilisation-content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod
        dignissim diam, ut eleifend velit accumsan vel. Nam laoreet leo ac nunc
        iaculis, quis consequat mi sodales. Proin auctor lorem in ante
        tincidunt, ut facilisis risus bibendum. zineb el hou many
      </Text>
    </Container>
  );
}
