import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../components/layout/Layout";

const PublicRoute = ({ restricted }) => {
  const isAuth = useSelector((state) => state.auth.isAuth);

  return isAuth && restricted ? (
    <Navigate to={"/profile"} replace />
  ) : (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PublicRoute;
