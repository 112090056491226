import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../components/layout/Layout";

const PrivateRoute = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  return isAuth ? (
    <Layout>
      <Outlet />{" "}
    </Layout>
  ) : (
    <Layout>
      <Navigate to={"/login"} replace />
    </Layout>
  );
};

export default PrivateRoute;
