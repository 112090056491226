// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-container {
  flex-direction: column;
  align-items: center;  
}

.mantine-15vclvg + .mantine-15vclvg,
.mantine-15vclvg:first-of-type {
  border-top: 0.0625rem solid #dee2e6;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.056);
}

.MuiAccordionSummary-expandIcon {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/HowItWorks.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;;EAEE,mCAAmC;EACnC,qBAAqB;EACrB,6CAA6C;AAC/C;;AAEA;EACE,aAAa;AACf","sourcesContent":[".title-container {\n  flex-direction: column;\n  align-items: center;  \n}\n\n.mantine-15vclvg + .mantine-15vclvg,\n.mantine-15vclvg:first-of-type {\n  border-top: 0.0625rem solid #dee2e6;\n  border-radius: 0.5rem;\n  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.056);\n}\n\n.MuiAccordionSummary-expandIcon {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
