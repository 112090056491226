import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  AppShell,
  Navbar,
  Header,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Container,
  Alert,
  Transition,
  Center,
  Loader,
  Modal,
  Button,
} from "@mantine/core";
import {
  IconNews,
  IconWallet,
  IconLogout,
  IconHome,
  IconInfoCircle,
} from "@tabler/icons-react";
import "../assets/css/Profile.css";
import { useNavigate } from "react-router-dom";
import Dashboard from "../components/layout/Profile/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { GetProfile } from "../features/auth/authSlice";
import {
  AddBusinessToProfile,
  GetOne,
} from "../features/businessCard/businessSlice";
import ModalBusinessCard from "../components/layout/Profile/ModalBusinessCard";
import DesignCard from "../screens/Design";
import UpdateBusinessCardForm from "../components/businessCard/updateBusiness";
import Portefeuille from "../components/layout/Profile/Portfeuille";
import NotebookLinkPopup from "../components/layout/Profile/NotebookLinkPopup";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
    [theme.fn.smallerThan("1231")]: {
      padding: "0.8rem 0.5rem",
    },
  },
  linkIcon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
    [theme.fn.smallerThan("1231")]: {
      marginRight: "0",
    },
  },
  linkActive: {
    "&, &:hover": {
      backgroundColor: "#f6f3db9b",
      color: "#b5a46a",
    },
  },
  logout: {
    [theme.fn.smallerThan("1231")]: {
      display: "none",
    },
  },
}));

const data = [
  {
    key: 1,
    label: "Dashboard",
    icon: IconHome,
    component: <Dashboard />,
  },
  {
    key: 2,
    label: "Portefeuille",
    icon: IconWallet,
    component: <Portefeuille />,
  },
  {
    key: 3,
    label: "Update BC",
    icon: IconHome,
    component: <UpdateBusinessCardForm />,
  },
  {
    key: 4,
    label: "Business card design",
    icon: IconNews,
    component: <DesignCard />,
  },
];

export default function Profile() {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Dashboard");
  const [opened, setOpened] = useState(false);
  const [component_, setComponent] = useState(data[0].component);
  const [businessCreated, setBusinessCreated] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, user } = useSelector((state) => state.auth);
  const { bc, isLoading, addBcToProfile, isSucessBc } = useSelector(
    (state) => state.bc
  );

  const location = useLocation();
  const [openedBc, setOpenedBc] = useState(false); // Modal state
  const params = new URLSearchParams(location.search);
  const added = params.get("added");
  const businessCardId = params.get("businessCardId");
  const notebookId = params.get("id");
  const [showPopup, setShowPopup] = useState(!!notebookId);

  const handlePopupClose = () => {
    setShowPopup(false);
    navigate("/profile"); // Update URL to remove notebook_id
  };

  useEffect(() => {
    if (!notebookId) setShowPopup(false); // Close popup if no notebook_id
  }, [notebookId]);

  const closeModal = () => {
    setOpenedBc(false);
  };

  useEffect(() => {
    if (token) {
      dispatch(GetProfile(token));
      dispatch(GetOne(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (added === "true" && businessCardId) {
      dispatch(AddBusinessToProfile({ businessCardId }));

      setOpenedBc(true); // Show the modal

      // Change the URL to remove the query params after the modal is shown, without reloading the page
      navigate("/profile", { replace: true });
    }
  }, [location, dispatch]);

  const links = data.map((item) => (
    <a
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      href="#"
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.label);
        setComponent(item.component);
        setOpened(false);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </a>
  ));

  const logOut = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  const theme = useMantineTheme();

  if (isLoading) {
    return (
      <Center h={400}>
        <Loader color="blue" />
      </Center>
    );
  }

  return (
    <>
      <AppShell
        styles={{
          main: {
            background:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        }}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        navbar={
          <Navbar
            p="md"
            hiddenBreakpoint="sm"
            hidden={!opened}
            width={{ sm: 200, lg: 300 }}
          >
            {links}
            <a href="#" className={classes.link} onClick={logOut}>
              <IconLogout className={classes.linkIcon} stroke={1.5} />
              <span className={classes.logout}>Se déconnecter</span>
            </a>
          </Navbar>
        }
        header={
          <Header height={{ base: 50, md: 70 }} p="md">
            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>
              <Text>InnerMind</Text>
            </div>
          </Header>
        }
      >
        <Container
          style={{
            position: "absolute",
            bottom: "80px",
            right: "0",
            zIndex: 1000,
          }}
        >
          <Transition
            mounted={businessCreated}
            transition="slide-up"
            duration={400}
            timingFunction="ease"
          >
            {(styles) => (
              <Alert
                variant="light"
                color="teal"
                title="Succès"
                icon={<IconInfoCircle />}
                withCloseButton
                style={styles}
                onClose={() => setBusinessCreated(false)}
              >
                Votre carte de visite a été ajoutée avec succès !
              </Alert>
            )}
          </Transition>
        </Container>

        {component_}
        {bc?.businessCard === null && (
          <ModalBusinessCard
            open={true}
            userData={user || {}}
            callBack={setBusinessCreated}
          />
        )}
        {showPopup && (
          <NotebookLinkPopup
            image="https://boutique.lematin.ma/wp-content/uploads/2023/02/Notebook-A4_390.jpg"
            onLinkNotebook={() => console.log("Link Notebook logic here")}
            onClose={handlePopupClose}
            userID={user?.userID}
            notebookID={notebookId}
          />
        )}
      </AppShell>

      {/* Success Modal */}
      <Modal opened={openedBc} onClose={closeModal} title="Success">
        <div style={{ textAlign: "center" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="checkmark-icon"
            style={{ width: 50, height: 50, color: "green" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2l4 -4"
            />
          </svg>
          <p>{addBcToProfile.message}</p>
        </div>
        <Button onClick={closeModal}>Close</Button>
      </Modal>
    </>
  );
}
