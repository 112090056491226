import React, { useState } from "react";
import {
  Card,
  Image,
  Text,
  Container,
  createStyles,
  Title,
  Flex,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconCheck, IconEdit, IconShare } from "@tabler/icons-react";
import myImage from "../../../assets/img/profile_logo.png";
import Social from "./Social";
import ContactInfo from "./ContatInfo";
import "../../../assets/css/Profile.css";

const useStyles = createStyles((theme) => ({
  card: {
    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },
  image: {
    borderRadius: "50%",
    marginRight: "1rem",
  },
  title: {
    whiteSpace: "nowrap",
  },
  wrapper: {
    justifyContent: "space-between",
    alignItems: "center",
    background: "#b5a46a",
    padding: "1rem",
    flexDirection: "column",
    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      padding: "0",
    },
  },
  flex: {
    alignItems: "center",
    marginBottom: "1rem",
  },
  link: {
    color: "#444",
    fontWeight: "500",
    fontSize: "1em",
    cursor: "pointer",
    width: "5rem",
    padding: "1rem",
    ":hover": {
      borderBottom: "1px solid #af8b0e42",
      color: "#444",
    },
    ":active": {
      borderBottom: "1px solid #b5a46a",
    },
  },
  linkWrapper: {
    position: "relative",
    margin: "2rem",
    borderBottom: "1px solid rgb(224, 224, 224)",
    transition: "all 0.3s ease",
    padding: "0rem",
  },
  icon: {
    color: "#b5a46a",
    background: "#fff",
    padding: "7px",
    borderRadius: "50%",
    transition: "boxShadow 0.5s",
    ":hover": {
      boxShadow: "0px 2px 10px #00000038",
    },
  },
}));

export default function CardInfo({ handleEditClick }) {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Basic");
  const [component_, setComponent] = useState(<ContactInfo />);
  const { user } = useSelector((state) => state.auth);
  const { bc } = useSelector((state) => state.bc);

  const data = [
    {
      link: "Basic",
      component: <ContactInfo />,
    },
    {
      link: "Social",
      component: <Social />,
    },
  ];

  const links = data.map((item, index) => (
    <a
      key={index}
      className={cx(classes.link, {
        [classes.linkActive]: item.link === active,
      })}
      onClick={(e) => {
        e.preventDefault();
        setActive(item.link);
        setComponent(item.component);
      }}
    >
      {item.link}
    </a>
  ));

  return (
    <Container m="0" pl="0" maw="22rem">
      <Card shadow="sm" pt="0" pr="0" pl="0" radius="md" withBorder>
        <Flex
          style={{
            justifyContent: "space-between",
            background: "#b5a46a",
            padding: "2rem 1rem",
          }}
        >
          <Link to="#">
            <IconShare className={classes.icon} />
          </Link>

          <Flex className={classes.wrapper}>
            <Image
              className={classes.image}
              src={myImage}
              height={"90px"}
              width={"95px"}
              alt="Profile"
            />
            <div>
              <Title className={classes.title} c="#fff" fz="1.2rem" ta="center">
                {bc ? bc.business_name : `${user.nom} ${user.prénom}`}
              </Title>
              <Text ta="center" c="#fff">
                Front-end Developer
              </Text>
            </div>
          </Flex>
          <Link onClick={handleEditClick}>
            <IconEdit className={classes.icon} />
          </Link>
        </Flex>
        <Flex className={classes.linkWrapper}>{links}</Flex>

        <div className="information-wrapper">{component_}</div>
      </Card>
    </Container>
  );
}
