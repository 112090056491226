// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mantine-1p3no9p {
  border-radius: 50%;
}

.information-wrapper {
  padding: 2rem;
}

.social-wrapper {
  justify-content: center;
}

/* cardInfo  */

.mantine-qb7utt {
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
  min-height: 6rem;
}

.mantine-qb7utt:focus {
  border-color: #bcaf4b;
}

/* stats */

.mantine-ii1rez {
  max-height: 5rem;
}


.upload_file .mantine-5xkobz{
  width: 7rem !important;
  min-height: 7rem !important;
  border-radius: 20.25rem !important;
  text-align: center;
}
.upload_file .mantine-Input-wrapper {
  text-align: center;
  display: flex !important;
  justify-content: center !important;
}


.mantine-xiucpp{
  width: 82%;
  height: auto;
  /* top:100px */
}


.button_modal{
  padding: 10px;
    justify-content: flex-end;
    margin-top: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/assets/css/Profile.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uBAAuB;AACzB;;AAEA,cAAc;;AAEd;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA,UAAU;;AAEV;EACE,gBAAgB;AAClB;;;AAGA;EACE,sBAAsB;EACtB,2BAA2B;EAC3B,kCAAkC;EAClC,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,kCAAkC;AACpC;;;AAGA;EACE,UAAU;EACV,YAAY;EACZ,cAAc;AAChB;;;AAGA;EACE,aAAa;IACX,yBAAyB;IACzB,gBAAgB;AACpB","sourcesContent":[".mantine-1p3no9p {\n  border-radius: 50%;\n}\n\n.information-wrapper {\n  padding: 2rem;\n}\n\n.social-wrapper {\n  justify-content: center;\n}\n\n/* cardInfo  */\n\n.mantine-qb7utt {\n  border-radius: 5px;\n  width: 100%;\n  padding: 1rem;\n  min-height: 6rem;\n}\n\n.mantine-qb7utt:focus {\n  border-color: #bcaf4b;\n}\n\n/* stats */\n\n.mantine-ii1rez {\n  max-height: 5rem;\n}\n\n\n.upload_file .mantine-5xkobz{\n  width: 7rem !important;\n  min-height: 7rem !important;\n  border-radius: 20.25rem !important;\n  text-align: center;\n}\n.upload_file .mantine-Input-wrapper {\n  text-align: center;\n  display: flex !important;\n  justify-content: center !important;\n}\n\n\n.mantine-xiucpp{\n  width: 82%;\n  height: auto;\n  /* top:100px */\n}\n\n\n.button_modal{\n  padding: 10px;\n    justify-content: flex-end;\n    margin-top: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
