import {
  createStyles,
  Container,
  Title,
  Text,
  rem,
  Accordion,
  Flex,
} from "@mantine/core";
import {
  IconCircleNumber1,
  IconCircleNumber2,
  IconCircleNumber3,
  IconQrcode,
  IconLink,
  IconShare,
} from "@tabler/icons-react";
import "../assets/css/HowItWorks.css";

const useStyles = createStyles((theme) => ({
  root: {
    backgroundSize: "cover",
    backgroundPosition: "center",

    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    height: "28.4rem",
  },

  inner: {
    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
    },
  },

  image: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  content: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      marginRight: 0,
      flexDirection: "column",
      paddingBottom: "0",
      paddingTop: "0",
    },
  },

  title: {
    color: "#000",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 0.9,
    fontSize: rem(38),

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      fontSize: rem(34),
      lineHeight: 1.15,
      marginBottom: "2rem",
    },
  },

  Flex: {
    alignItems: "center",
  },
}));

const charactersList = [
  {
    id: "bender",
    IconNumber: IconCircleNumber1,
    Icon: IconQrcode,
    label: "Scannez le code QR",
    content:
      "Après avoir reçu votre bloc-notes InnerMind, vous pouvez configurer votre compte et connecter votre carte de visite en scannant le code QR sur la première page.",
  },

  {
    id: "carol",
    IconNumber: IconCircleNumber2,
    Icon: IconLink,
    label: "Connectez votre carte de visite",
    content:
      "Après avoir vérifié votre compte, vous pourrez connecter votre carte InnerMind en accédant à votre tableau de bord InnerMind où vous pourrez ajouter vos informations personnelles et professionnelles.",
  },

  {
    id: "homer",
    IconNumber: IconCircleNumber3,
    Icon: IconShare,
    label: "Partagez votre carte de visite",
    content:
      "Partagez votre carte de visite avec d'autres personnes, qui pourront facilement accéder à votre carte de visite en scannant le code QR situé en haut du bloc-notes.",
  },
];

function AccordionLabel({ label, IconNumber, Icon }) {
  const { classes } = useStyles();
  return (
    <Flex className={classes.Flex} noWrap>
      <IconNumber color="#515151" size="37px" />
      <Flex className="title-container">
        <Icon size="40px" color="#bcaf4b" />
        <Text weight="500" w="18rem" ta="center">
          {label}
        </Text>
      </Flex>
    </Flex>
  );
}

export function HowItWorks() {
  const { classes } = useStyles();
  const items = charactersList.map((item) => (
    <Accordion.Item mb={"1.5rem"} value={item.id} key={item.label}>
      <Accordion.Control>
        <AccordionLabel {...item} />
      </Accordion.Control>
      <Accordion.Panel>
        <Text w={"290px"} m="auto" size="sm">
          {item.content}
        </Text>
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <div className={`${classes.root}`}>
      <Container size="lg">
        <div className={classes.inner}>
          <Flex gap="8rem" mr="0" className={classes.content}>
            <div>
              <Title mb={"2rem"} className={classes.title}>
                Comment ça marche ?
              </Title>
              <Text c="#3f3e3e" fontWeight="400" w="25rem">
                InnerMind - Le bloc-notes qui vous permet de partager votre
                carte de visite facilement. Suivez ces étapes simples pour
                connecter votre carte de visite.
              </Text>
            </div>

            <Accordion
              shadow="1px"
              border="1px solid black"
              chevronPosition="right"
              chevronSize="0"
              variant="contained"
            >
              {items}
            </Accordion>
          </Flex>
        </div>
      </Container>
    </div>
  );
}
