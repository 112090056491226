import React from "react";

function SocialMedia({ data, Icon, name }) {
  const getSocialMediaUrl = (platform, username) => {
    switch (platform.toLowerCase()) {
      case "facebook":
        return `https://facebook.com/${username}`;
      case "twitter":
        return `https://twitter.com/${username}`;
      case "linkedin":
        return `https://linkedin.com/in/${username}`;
      case "instagram":
        return `https://instagram.com/${username}`;
      default:
        return "#";
    }
  };

  const url = getSocialMediaUrl(name, data);

  return (
    <a rel="nofollow noopener noreferrer" href={url} target="_blank">
      <div
        className="qrc_social_icon nofollow"
        style={{ backgroundColor: "black" }}
      >
        <Icon color="white" size={20} />
      </div>
    </a>
  );
}

export default SocialMedia;
