import { createStyles, Card, Overlay, Text, rem } from "@mantine/core";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  card: {
    height: rem(450),
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "0",
  },

  content: {
    ...theme.fn.cover(),
    padding: theme.spacing.xl,
    zIndex: 1,
    maxWidth: "1200px",
    margin: "2rem auto",
  },

  action: {
    position: "absolute",
    bottom: theme.spacing.xl,
    right: theme.spacing.xl,
    border: "0.5px solid white",
    borderRadius: "8px",
    padding: "10px 15px",
    fontSize: "0.9rem",
    color: "white",
    transition: "border-color 0.5s, color 0.5s",

    "&:hover": {
      color: "#ffffffcc;",
      borderColor: "#ffffffcc",
      cursor: "pointer",
    },
  },

  title: {
    color: theme.white,
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
  },

  description: {
    color: theme.white,
    maxWidth: rem(220),
  },
}));

export function Banner({
  title,
  description,
  image,
  action,
  style,
  className,
  ...others
}) {
  const { classes, cx } = useStyles();

  return (
    <Card
      radius="md"
      style={{ backgroundImage: `url(${image})`, ...style }}
      className={cx(classes.card, className)}
      {...others}
    >
      <Overlay
        gradient={`linear-gradient(to top, #232121 0%, #232121 100%)`}
        opacity={0.55}
        zIndex={0}
      />

      <div className={classes.content}>
        <Text size="1.8rem" weight={500} className={classes.title}>
          Get your InnerMind right now !
        </Text>

        <Text size="1.2rem" maw="28rem" className={classes.description}>
          I don't know what to write here
        </Text>

        <Text size="1.2rem" maw="28rem" className={classes.description}>
          Avez vous des questions ?
        </Text>

        <Link
          className={classes.action}
          variant="white"
          to="/contact"
          color="dark"
          component="a"
          size="xs"
          href={action?.link}
        >
          Contactez nous
        </Link>
      </div>
    </Card>
  );
}
