import React, { useEffect, useState } from "react";
import { HomeHeader } from "../components/layout/HomeHeader";
import { HowItWorksSection } from "../components/layout/HowItWorksSection";
import { Banner } from "../components/layout/Banner";
import { Features } from "../components/layout/FeaturesSection";
import { useDispatch, useSelector } from "react-redux";
import { Home as Landing } from "../features/home/homeSlice";

import "../assets/css/Home.css";

function Home() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.home.isLoading);
  const [loader, setLoader] = useState(true);
  React.useEffect(() => {
    dispatch(Landing());
  }, [dispatch]);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, []);
  return (
    <>
      {/* <Loader isLoading={loader}/> */}
      <HomeHeader />
      <HowItWorksSection />
      <Banner />
      <Features id="features" />
    </>
  );
}

export default Home;
