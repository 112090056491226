import React from "react";
import "../../../assets/css/Custom.css";
import {
  FaUpload,
  FaPlus,
  FaPhoneAlt,
  FaRegEnvelope,
  FaRegEdit,
} from "react-icons/fa";
import { useState } from "react";
import myImage from "../../../assets/img/profile_logo.png";
import SocialMedia from "../../../scan_side/SocialMedia";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
} from "@tabler/icons-react";
import HeaderBusinessCard from "../../../BusCard/Components/Header";
import ContactSectionBusiness from "../../../BusCard/Components/ContactSection";
import SocialMediaBusiness from "../../../BusCard/Components/SocialMedia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const socialMediaIcons = {
  facebook: IconBrandFacebook,
  twitter: IconBrandTwitter,
  linkedin: IconBrandLinkedin,
  instagram: IconBrandInstagram,
};

export default function BusinessCardPortfeuille({ data }) {
  const [isExpanded, setIsExpanded] = useState({});

  const handleDownload = () => {
    const vcfData = `
      BEGIN:VCARD
      VERSION:3.0
      FN:${data.business_name || ""}
      TEL:${data.phone_number || ""}
      EMAIL:${data.email_adress || ""}
      END:VCARD
    `;

    const blob = new Blob([vcfData], { type: "text/vcard;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${data.business_name || "Contact"}Contacts.vcf`;

    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    a.dispatchEvent(event);

    window.URL.revokeObjectURL(url);
  };
  const socialMediaAccounts = data.social_media_accounts || {};

  const toggleExpand = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <div
      style={{
        border: "1px solid black",
        marginLeft: "10px",
        marginBottom: "10px",
      }}
    >
      <HeaderBusinessCard data={data} />

      {isExpanded && (
        <div
          onClick={toggleExpand}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      )}

      {!isExpanded && (
        <>
          <ContactSectionBusiness data={data} />
          <SocialMediaBusiness data={data} />

          <div
            onClick={toggleExpand}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </div>
        </>
      )}
    </div>
  );
}
