import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import viewApi from "./viewApi";

const initialState = {
  isLoadingCard: false,
  message: "",
  businessCard: null,
};

// login

export const GetViewScan = createAsyncThunk(
  "view/ScanQR",
  async (view_id, thunkAPI) => {
    try {
      return await viewApi.ScanQrView({ view_id });
    } catch (error) {
      const message = error.toString() || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetViewScan.pending, (state) => {
        state.isLoadingCard = true;
      })
      .addCase(GetViewScan.fulfilled, (state, action) => {
        state.isLoadingCard = false;
        state.businessCard = action.payload;
      })
      .addCase(GetViewScan.rejected, (state, action) => {
        state.isSucess = false;
        state.message = action.payload;
      });
  },
});

export const { reset } = viewSlice.actions;
export default viewSlice.reducer;
