import {
  createStyles,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
} from "@mantine/core";
import { IconQrcode, IconLink, IconShare } from "@tabler/icons-react";

const mockdata = [
  {
    title: "Scannez le code QR",
    description:
      "Scannez le code qui se trouve sur la première page de votre bloc note Innermind.",
    icon: IconQrcode,
  },
  {
    title: "Connectez votre carte de visite",
    description:
      "Ajoutez vos informations personnelles et professionnelles pour connecter votre carte InnerMind.",
    icon: IconLink,
  },
  {
    title: "Partagez votre carte de visite",
    description:
      "Partagez le QR code situé sur la couverture de votre bloc note avec les autres pour scanner.",
    icon: IconShare,
  },
];

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(34),
    fontWeight: 900,
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.lg,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
    flexDirection: "column",
    alignItems: "center",
    background: "#ffffff73",
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: "#bcaf4b",
      width: rem(45),
      height: rem(2),
      margin: "auto",
      marginTop: "0.5rem",
    },
  },
}));

export function HowItWorksSection() {
  const { classes } = useStyles();
  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={classes.card}
      padding="xl"
      display="flex"
    >
      <feature.icon size={rem(50)} stroke={2} color="#bcaf4b" />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md" ta="center">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm" ta={"center"}>
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <Container size="lg" p="5rem">
      <Title order={2} className={classes.title} ta="center">
        Connectez votre carte de visite InnerMind !
      </Title>

      <SimpleGrid
        cols={3}
        spacing="xl"
        mt={50}
        breakpoints={[{ maxWidth: "md", cols: 1 }]}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
