import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextInput,
  Textarea,
  Button,
  Container,
  Group,
  Paper,
  Title,
} from "@mantine/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Update, GetOne } from "../../../features/businessCard/businessSlice";

const UpdateBusinessCardForm = () => {
  const dispatch = useDispatch();
  const { bc, isSuccess, message } = useSelector((state) => state.bc);

  const [formLoading, setFormLoading] = useState(false);
  const [formData, setFormData] = useState({
    business_name: "",
    email_adress: "",
    phone_number: "",
    business_adress: "",
    business_details: "",
    social_media_accounts: {
      facebook: "",
      twitter: "",
      linkedin: "",
      instagram: "",
    },
    profession: "",
    societe: "",
  });

  useEffect(() => {
    if (bc) {
      setFormData(bc);
    }
  }, [bc]);

  //   useEffect(() => {
  //     const token = localStorage.getItem("token");
  //     if (token) {
  //       dispatch(GetOne(token));
  //     }
  //   }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      setFormLoading(false);
      toast.success("Business card updated successfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (message) {
      setFormLoading(false);
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isSuccess, message]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("social_")) {
      setFormData((prevData) => ({
        ...prevData,
        social_media_accounts: {
          ...prevData.social_media_accounts,
          [name.split("_")[1]]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormLoading(true);
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(Update({ data: formData, token }));
    } else {
      toast.error("No authentication token found. Please log in.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setFormLoading(false);
    }
  };

  return (
    <Container size="sm" my={40}>
      <ToastContainer />
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Title order={2} align="center" mb="xl">
          Update Business Card
        </Title>
        <form onSubmit={handleSubmit}>
          <TextInput
            label="Business Name"
            placeholder="Enter your business name"
            name="business_name"
            value={formData.business_name}
            onChange={handleChange}
            required
          />
          <TextInput
            label="Email Address"
            placeholder="Enter your email address"
            name="email_adress"
            value={formData.email_adress}
            onChange={handleChange}
            required
          />
          <TextInput
            label="Phone Number"
            placeholder="Enter your phone number"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            required
          />
          <TextInput
            label="Business Address"
            placeholder="Enter your business address"
            name="business_adress"
            value={formData.business_adress}
            onChange={handleChange}
            required
          />
          <Textarea
            label="Business Details"
            placeholder="Enter your business details"
            name="business_details"
            value={formData.business_details}
            onChange={handleChange}
            required
          />
          <TextInput
            label="Profession"
            placeholder="Enter your profession"
            name="profession"
            value={formData.profession}
            onChange={handleChange}
            required
          />
          <TextInput
            label="Company"
            placeholder="Enter your company name"
            name="societe"
            value={formData.societe}
            onChange={handleChange}
            required
          />
          <TextInput
            label="Facebook"
            placeholder="Enter your Facebook username"
            name="social_facebook"
            value={formData.social_media_accounts.facebook}
            onChange={handleChange}
          />
          <TextInput
            label="Twitter"
            placeholder="Enter your Twitter username"
            name="social_twitter"
            value={formData.social_media_accounts.twitter}
            onChange={handleChange}
          />
          <TextInput
            label="LinkedIn"
            placeholder="Enter your LinkedIn username"
            name="social_linkedin"
            value={formData.social_media_accounts.linkedin}
            onChange={handleChange}
          />
          <TextInput
            label="Instagram"
            placeholder="Enter your Instagram username"
            name="social_instagram"
            value={formData.social_media_accounts.instagram}
            onChange={handleChange}
          />
          <Group position="apart" mt="lg">
            <Button
              type="submit"
              fullWidth
              mt="xl"
              color="blue"
              loading={formLoading}
            >
              Update Business Card
            </Button>
          </Group>
        </form>
      </Paper>
    </Container>
  );
};

export default UpdateBusinessCardForm;
