import { Container, Title, Accordion, createStyles, rem } from "@mantine/core";
import "../assets/css/FaqPage.css";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    minHeight: 650,
  },

  title: {
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export function FaqPage() {
  const { classes } = useStyles();
  return (
    <Container size="sm" className={`${classes.wrapper} page-wrapper`}>
      <Title align="center" className={classes.title}>
        Aide & FAQ
      </Title>

      <Accordion variant="separated">
        <Accordion.Item className={classes.item} value="reset-password">
          <Accordion.Control>InnerMind Notebook</Accordion.Control>
          <Accordion.Panel>
            InnerMind Notebook est un carnet innovant qui permet de partager des
            cartes de visite électroniques facilement et de convertir des notes
            manuscrites en notes numériques.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="another-account">
          <Accordion.Control>
            Comment fonctionne InnerMind Notebook ?
          </Accordion.Control>
          <Accordion.Panel>
            InnerMind Notebook fonctionne grâce à deux codes QR situés sur le
            carnet. Le premier code, situé sur la couverture du carnet, permet
            de partager une carte de visite électronique. Le deuxième code,
            situé sur la première page du carnet, permet de créer un compte
            InnerMind pour configurer votre carte de visite électronique.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="newsletter">
          <Accordion.Control>
            Créer une carte de visite électronique avec InnerMind Notebook
          </Accordion.Control>
          <Accordion.Panel>
            Pour créer une carte de visite électronique avec InnerMind Notebook,
            vous devez acheter un carnet InnerMind et configurer votre compte
            InnerMind en scannant le code QR situé sur la première page du
            carnet. Vous pourrez ensuite ajouter toutes vos informations
            professionnelles et les réseaux sociaux sur votre carte de visite
            électronique.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="credit-card">
          <Accordion.Control>
            Partager une carte de visite électronique
          </Accordion.Control>
          <Accordion.Panel>
            Pour partager une carte de visite électronique, vous devez
            simplement partager le code QR qui se trouve sur la couverture du
            carnet avec la personne avec laquelle vous souhaitez partager votre
            carte de visite électronique. Cette personne pourra alors scanner le
            code QR et accéder à votre carte de visite électronique.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="payment">
          <Accordion.Control>
            Convertir des notes manuscrites en notes numériques avec InnerMind
            Notebook
          </Accordion.Control>
          <Accordion.Panel>
            Pour convertir des notes manuscrites en notes numériques avec
            InnerMind Notebook, vous devez simplement scanner les pages de notes
            avec votre téléphone en utilisant l'application InnerMind. Les notes
            seront alors automatiquement converties en notes numériques et
            sauvegardées sur votre compte InnerMind.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="credit-card">
          <Accordion.Control>
            Accéder à mes notes numériques avec InnerMind Notebook
          </Accordion.Control>
          <Accordion.Panel>
            Pour accéder à vos notes numériques avec InnerMind Notebook, vous
            devez simplement vous connecter à votre compte InnerMind sur le site
            web ou l'application. Vous pourrez alors accéder à toutes vos notes
            numériques depuis n'importe quel appareil connecté à Internet.
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}
