import {
  createStyles,
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
  Box,
  Flex,
  rem,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
  IconSun,
  IconPhone,
  IconMapPin,
  IconAt,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    minWidth: "100%",
    boxSizing: "border-box",
    backgroundImage: "none",
    paddingTop: "6rem",
    paddingBottom: "6rem",
    borderRadius: theme.radius.md,
    padding: `calc(${theme.spacing.xl} * 2.5)`,

    [theme.fn.smallerThan("sm")]: {
      padding: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: "#000",
    lineHeight: 1,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: rem(300),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  form: {
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    backgroundVolor: "transparent",
    border: "1px solid rgba(72, 72, 72, 0.382)",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.093)",

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  social: {
    color: theme.white,

    "&:hover": {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    "&::placeholder": {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },

  control: {
    backgroundColor: "#4c4c4c",
    width: "100%",
    transition: "backgroundColor 0.5s",
    ":hover": {
      backgroundColor: "#373737",
    },
  },
}));

const MOCKDATA = [
  { description: "Strava@Strava.com", Icon: IconAt },
  { description: "+212 653 1509 49", Icon: IconPhone },
  { description: "88, Boulevard MED V, Casablanca.", Icon: IconMapPin },
  { description: "Lundi au Vendredi : 8H30 à 17H", Icon: IconSun },
];

const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];

export function Contact() {
  const { classes } = useStyles();

  const icons = social.map((Icon, index) => (
    <ActionIcon
      key={index}
      size={28}
      className={classes.social}
      variant="transparent"
    >
      <Icon size="1.4rem" stroke={1.5} />
    </ActionIcon>
  ));

  return (
    <div className={`${classes.wrapper} page-wrapper`}>
      <SimpleGrid
        minWidth="50rem"
        cols={2}
        spacing={100}
        breakpoints={[{ maxWidth: "sm", cols: 1 }]}
      >
        <div className="contact-left">
          <Title className={classes.title}>Nous contacter</Title>
          <Text c="#9d9c9c" className={classes.description} mt="sm" mb={30}>
            Pour toute remarque ou suggestion, n'hésitez pas à prendre contact
            avec nos équipes.
          </Text>

          <Box>
            {MOCKDATA.map(({ description, Icon }) => (
              <Flex alignItems="center" justifyContent="center" mb="0.8rem">
                <Icon size="1.4rem" color="#9d9c9c" stroke={1.5} />
                <Text ml="0.5rem" fontSize={14} color="#9d9c9c">
                  {description}
                </Text>
              </Flex>
            ))}
          </Box>

          <Group mt="xl">{icons}</Group>
        </div>
        <div className={classes.form}>
          <TextInput
            label="Email"
            placeholder="Email"
            w="100%"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <TextInput
            label="Nom"
            placeholder="Nom complet"
            mt="md"
            w="100%"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <Textarea
            label="Message"
            placeholder="Votre message"
            minRows={4}
            mt="md"
            w="100%"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />

          <Group position="right" mt="md">
            <Button backgroundColor="#4c4c4c" className={classes.control}>
              Envoyer
            </Button>
          </Group>
        </div>
      </SimpleGrid>
    </div>
  );
}
