import { createStyles, Header, Group, Box } from "@mantine/core";
import { Link } from "react-router-dom";
import "../../assets/css/Header.css";

const useStyles = createStyles((theme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },
}));

export function NormalHeader() {
  const { classes } = useStyles();

  return (
    <Box pb={120}>
      <Header height={60} px="md">
        <Group position="apart" sx={{ height: "100%" }}>
          <Link to="/" className={"header-logo"} style={{ color: "white" }}>
            InnerMind
          </Link>

          <div className={classes.hiddenMobile}>
            <Link mr={50} to="/login" className="header-login">
              Se connecter
            </Link>
          </div>
        </Group>
      </Header>
    </Box>
  );
}
