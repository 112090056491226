// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-flex .mantine-Paper-root {
  border: 1px solid rgba(72, 72, 72, 0.382);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.093);
}

.mantine-Input-input .mantine-TextInput-input .mantine-g9emdi {
  margin-bottom: 1.5rem;
}

.mantine-1fzet7j {
  margin-bottom: 0.5rem;
}

.mantine-Checkbox-label {
  font-weight: 500;
  color: #828282;
  font-size: 14px;
}

.mantine-1t0td6c {
  margin-bottom: 0.8rem;
}

.mantine-1ryt1ht {
  font-weight: bold;
  font-size: 0.9rem;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Login.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,6CAA6C;AAC/C;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".page-flex .mantine-Paper-root {\n  border: 1px solid rgba(72, 72, 72, 0.382);\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.093);\n}\n\n.mantine-Input-input .mantine-TextInput-input .mantine-g9emdi {\n  margin-bottom: 1.5rem;\n}\n\n.mantine-1fzet7j {\n  margin-bottom: 0.5rem;\n}\n\n.mantine-Checkbox-label {\n  font-weight: 500;\n  color: #828282;\n  font-size: 14px;\n}\n\n.mantine-1t0td6c {\n  margin-bottom: 0.8rem;\n}\n\n.mantine-1ryt1ht {\n  font-weight: bold;\n  font-size: 0.9rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
