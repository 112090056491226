import {
  createStyles,
  Title,
  TextInput,
  Button,
  Container,
  Group,
  Anchor,
  Center,
  Text,
  rem,
  Flex,
} from "@mantine/core";
import { IconArrowLeft, IconLock } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { sendResetCode, resetPassword } from "../features/auth/authSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/PasswordReset.css";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(26),
    fontWeight: 900,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  controls: {
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column-reverse",
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
}));

export function PasswordReset() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const { isLoading } = useSelector((state) => state.auth);

  const handleSendCode = (e) => {
    e.preventDefault();
    dispatch(sendResetCode({ email }))
      .unwrap()
      .then(() => {
        toast.success("Code de réinitialisation envoyé à l'e-mail", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsCodeSent(true);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    dispatch(resetPassword({ email, resetCode, newPassword }))
      .unwrap()
      .then(() => {
        toast.success("Mot de passe réinitialisé avec succès", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/login");
      })
      .catch((error) => {
        toast.success(error.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <Container className="page-flex page-wrapper" size={460} my={30}>
      <ToastContainer />
      <Flex
        className="password-reset-flex"
        withBorder
        shadow="md"
        p={30}
        radius="md"
        mt="xl"
      >
        <IconLock align="center" size={rem(80)} />

        <Title className={classes.title} align="center">
          {isCodeSent
            ? "Réinitialisez votre mot de passe"
            : "Problèmes de connexion ?"}
        </Title>
        <Text c="dimmed" fz="sm" ta="center">
          {isCodeSent
            ? "Entrez le code que vous avez reçu dans votre e-mail et définissez un nouveau mot de passe."
            : "Entrez votre adresse e-mail, et nous vous enverrons un code pour récupérer votre compte."}
        </Text>
        <form onSubmit={isCodeSent ? handleResetPassword : handleSendCode}>
          <TextInput
            label="Votre e-mail"
            placeholder="Saisir votre adresse mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isCodeSent}
          />
          {isCodeSent && (
            <>
              <TextInput
                label="Code de réinitialisation"
                placeholder="Entrez le code de réinitialisation"
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
                required
              />
              <TextInput
                label="Nouveau mot de passe"
                placeholder="Entrez votre nouveau mot de passe"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </>
          )}
          <Group position="apart" mt="lg" className={classes.controls}>
            <Button
              type="submit"
              className={classes.control}
              loading={isLoading}
            >
              {isCodeSent ? "Réinitialiser" : "Envoyer"}
            </Button>
          </Group>
        </form>
        <Anchor color="dimmed" size="sm" className={classes.control}>
          <Text color="dimmed" size="sm" align="center" mt={5}>
            Vous n'avez pas de compte ?{" "}
            <Link to="/register" className="register-link">
              Inscrivez-vous
            </Link>
          </Text>
          <Center inline>
            <IconArrowLeft color="#b09c08" size={rem(12)} stroke={1.5} />
            <Link to="/login" className="login-return" ml={5}>
              Retour à la page de connexion
            </Link>
          </Center>
        </Anchor>
      </Flex>
    </Container>
  );
}
