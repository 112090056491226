import {
  Paper,
  TextInput,
  Text,
  Button,
  Group,
  SimpleGrid,
  createStyles,
  rem,
  PasswordInput,
  Container,
  Title,
} from "@mantine/core";

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "../assets/css/Register.css";
import { useDispatch, useSelector } from "react-redux";
import { Register as signUp } from "../features/auth/authSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan("sm");

  return {
    wrapper: {
      display: "flex",
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
      borderRadius: theme.radius.lg,
      // padding: rem(4),
      border: `${rem(1)} solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.colors.gray[2]
      }`,

      [BREAKPOINT]: {
        flexDirection: "column",
      },
    },

    form: {
      boxSizing: "border-box",
      flex: 1,
      padding: theme.spacing.xl,
      paddingLeft: `calc(${theme.spacing.xl} * 2)`,
      borderLeft: 0,

      [BREAKPOINT]: {
        padding: theme.spacing.md,
        paddingLeft: theme.spacing.md,
      },
    },

    fields: {
      marginTop: rem(-12),
    },

    fieldInput: {
      flex: 1,

      "& + &": {
        marginLeft: theme.spacing.md,

        [BREAKPOINT]: {
          marginLeft: 0,
          marginTop: theme.spacing.md,
        },
      },
    },

    fieldsGroup: {
      display: "flex",

      [BREAKPOINT]: {
        flexDirection: "column",
      },
    },

    contacts: {
      boxSizing: "border-box",
      position: "relative",
      borderRadius: theme.radius.lg,
      backgroundSize: "cover",
      backgroundPosition: "center",
      border: `${rem(1)} solid transparent`,
      padding: theme.spacing.xl,
      flex: `0 0 ${rem(280)}`,

      [BREAKPOINT]: {
        marginBottom: theme.spacing.sm,
        paddingLeft: theme.spacing.md,
      },
    },

    title: {
      marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,

      [BREAKPOINT]: {
        marginBottom: theme.spacing.xl,
      },
    },
  };
});

export function Register({ onValidate, userData, prevStep, ...props }) {
  const { classes } = useStyles();
  const { message } = useSelector((state) => state.auth);
  const [nom, setNom] = useState(userData.nom);
  const [prénom, setPrénom] = useState(userData.prénom);
  const [email, setEmail] = useState(userData.email);
  const [password, setPassword] = useState(userData.password);
  const [messageForm, setMessageForm] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [emailMessage, setemailMessage] = useState(true);
  const [passwordMessage, setpasswordMessage] = useState(true);
  const notebook_id = queryParams.get("id");
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    nom: "",
    prénom: "",
    email: "",
    password: "",
  });

  const handleSubmit = () => {
    let validationErrors = {};

    // Validate Nom
    if (!nom || nom.trim() === "") {
      validationErrors.nom = "Nom is required";
    }

    // Validate Prenom
    if (!prénom || prénom.trim() === "") {
      validationErrors.prénom = "Prénom is required";
    }

    // Validate Email
    if (!email || email.trim() === "") {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = "Email is invalid";
    }

    // Validate Password
    if (!password || password.trim() === "") {
      validationErrors.password = "Password is required";
    } else if (password.length < 6) {
      validationErrors.password = "Password must be at least 6 characters";
    }

    setErrors(validationErrors);

    // If no errors, proceed with submission
    if (Object.keys(validationErrors).length === 0) {
      onValidate({ nom, prénom, email, password, step_1: true });
      console.log("Form submitted successfully");
    }
  };

  // useEffect(() => {

  // },[dispatch])

  const ToastMessage = () => {
    toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  return (
    <Container size={420} my={40}>
      <Paper radius="md" p="xl" withBorder {...props}>
        <ToastContainer />
        {/* <form className={classes.form} onSubmit={submitHandler}> */}
        <Title ta="center">InnerMind</Title>
        <Title
          fz="15px"
          c="#828282"
          fw={500}
          mt="lg"
          ta="center"
          className={classes.title}
        >
          Inscrivez-vous pour pouvoir utiliser votre compte InnerMind
        </Title>
        <div className={classes.fields}>
          <SimpleGrid cols={1} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
            <TextInput
              onChange={(e) => setNom(e.target.value)}
              label="Nom"
              placeholder="Nom"
              value={nom}
              error={errors.nom}
            />
            <TextInput
              onChange={(e) => setPrénom(e.target.value)}
              label="Prénom"
              placeholder="Prénom"
              value={prénom}
              error={errors.prénom}
            />
            <TextInput
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              placeholder="E-mail"
              value={email}
              type="email"
              error={errors.email}
            />
            {messageForm}
          </SimpleGrid>
          <p>{emailMessage}</p>
          <SimpleGrid>
            <PasswordInput
              onChange={(e) => setPassword(e.target.value)}
              label="Mot de passe"
              placeholder="Mot de passe"
              value={password}
              error={errors.password}
            />
          </SimpleGrid>
          <p>{passwordMessage}</p>
          <p>Vous pouvez utiliser des lettres, des chiffres et des points</p>
          <Group position="right" mt="md" style={{ flexDirection: "column" }}>
            <Group className="button_modal">
              <Button variant="default" onClick={prevStep}>
                Back
              </Button>
              <Button
                variant="gradient"
                gradient={{ from: "blue", to: "cyan", deg: 90 }}
                size="xs"
                onClick={() => handleSubmit()}
              >
                Next step
              </Button>
            </Group>
            <Text color="dimmed" size="sm" align="center" mt={5}>
              Vous avez un compte ?{" "}
              <Link
                to={notebook_id ? `/login?id=${notebook_id}` : "/login"}
                className="register-link"
              >
                Connectez-vous
              </Link>
            </Text>
          </Group>
        </div>
        {/* </form> */}
      </Paper>
    </Container>
  );
}
