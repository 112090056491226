import axios from "axios";
import { API_HOST } from "../../../utils/API";

const ScanQrView = async ({ view_id }) => {
  const response = await axios.get(`${API_HOST}/view/${view_id}`);
  return response.data;
};

const viewApi = { ScanQrView };
export default viewApi;
