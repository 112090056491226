// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*FeaturesSection style */

.mantine-1tqlqgk {
  max-width: 1200px;
  margin: 5rem auto;
}

.mantine-Grid-root .mantine-1xi6kly {
  margin: 0;
}

.mantine-1m3dxl3 {
  transition: background-color 0.5s;
}

.mantine-1m3dxl3:hover {
  cursor: pointer;
  background-color: #f4f4f499;
}

.mantine-1lm8lcc {
  background-image: linear-gradient(133deg, #bcaf4b 0%, #d8cc6e 100%);
}

/* HowItWorksSection style */

.mantine-1r62vn4 {
  margin: 3rem auto;
}

.mantine-Timeline-itemTitle {
  font-size: 1.3rem;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Home.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,mEAAmE;AACrE;;AAEA,4BAA4B;;AAE5B;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["/*FeaturesSection style */\n\n.mantine-1tqlqgk {\n  max-width: 1200px;\n  margin: 5rem auto;\n}\n\n.mantine-Grid-root .mantine-1xi6kly {\n  margin: 0;\n}\n\n.mantine-1m3dxl3 {\n  transition: background-color 0.5s;\n}\n\n.mantine-1m3dxl3:hover {\n  cursor: pointer;\n  background-color: #f4f4f499;\n}\n\n.mantine-1lm8lcc {\n  background-image: linear-gradient(133deg, #bcaf4b 0%, #d8cc6e 100%);\n}\n\n/* HowItWorksSection style */\n\n.mantine-1r62vn4 {\n  margin: 3rem auto;\n}\n\n.mantine-Timeline-itemTitle {\n  font-size: 1.3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
