// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mantine-1gs26ep {
  color: white;
  margin-top: 0;
  background-image: linear-gradient(to top, #333030f8 0%, #181818 100%);
  padding-bottom: 0;
}

.mantine-g244cg {
  font-family: "Roboto", sans-serif;
  width: 170px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0.8rem;
}

.mantine-x1obhy {
  width: 12rem;
}

.mantine-16ru5kt {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #a1974b;
  margin-bottom: 1rem;
}

.mantine-asm909 {
  transition: border 0.3s;
}

.mantine-asm909:hover {
  border: 1px solid #a1974b;
  background-color: transparent;
}

/* normal footer style */

.mantine-1xe0c8z {
  padding-bottom: calc(0.9rem * 2);
  padding-top: calc(0.9rem * 2);
  margin-top: 2.5rem;
}

.mantine-1ojpbny {
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Footer.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,aAAa;EACb,qEAAqE;EACrE,iBAAiB;AACnB;;AAEA;EACE,iCAAiC;EACjC,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;AAC/B;;AAEA,wBAAwB;;AAExB;EACE,gCAAgC;EAChC,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap\");\n\n.mantine-1gs26ep {\n  color: white;\n  margin-top: 0;\n  background-image: linear-gradient(to top, #333030f8 0%, #181818 100%);\n  padding-bottom: 0;\n}\n\n.mantine-g244cg {\n  font-family: \"Roboto\", sans-serif;\n  width: 170px;\n  color: #fff;\n  font-weight: 400;\n  margin-bottom: 0.8rem;\n}\n\n.mantine-x1obhy {\n  width: 12rem;\n}\n\n.mantine-16ru5kt {\n  font-family: \"Roboto\", sans-serif;\n  font-weight: 500;\n  color: #a1974b;\n  margin-bottom: 1rem;\n}\n\n.mantine-asm909 {\n  transition: border 0.3s;\n}\n\n.mantine-asm909:hover {\n  border: 1px solid #a1974b;\n  background-color: transparent;\n}\n\n/* normal footer style */\n\n.mantine-1xe0c8z {\n  padding-bottom: calc(0.9rem * 2);\n  padding-top: calc(0.9rem * 2);\n  margin-top: 2.5rem;\n}\n\n.mantine-1ojpbny {\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
