import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import homeReducer from "../features/home/homeSlice";
import bcReducer from "../features/businessCard/businessSlice";
import viewSlice from "../features/QrScann/View/viewSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    home: homeReducer,
    bc: bcReducer,
    qr_scanner: viewSlice,
  },
});
