// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mantine-4galam {
  font-size: 23px;
  color: #1c1e21;
  margin: 0.5rem 0;
}

.password-reset-flex {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(72, 72, 72, 0.382);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.093);
  border-radius: 10px;
  margin-top: 0;
}

.mantine-1uccbrv {
  margin-bottom: 0.5rem;
}

.mantine-pve00c {
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.password-reset-flex .mantine-TextInput-root,
.password-reset-flex .mantine-Group-root {
  width: 100%;
}

.password-reset-flex .mantine-Center-root {
  margin-top: 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/PasswordReset.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,yCAAyC;EACzC,6CAA6C;EAC7C,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iCAAiC;EACjC,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".mantine-4galam {\n  font-size: 23px;\n  color: #1c1e21;\n  margin: 0.5rem 0;\n}\n\n.password-reset-flex {\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid rgba(72, 72, 72, 0.382);\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.093);\n  border-radius: 10px;\n  margin-top: 0;\n}\n\n.mantine-1uccbrv {\n  margin-bottom: 0.5rem;\n}\n\n.mantine-pve00c {\n  font-family: \"Roboto\", sans-serif;\n  letter-spacing: 1px;\n  font-size: 0.9rem;\n  font-weight: 500;\n  margin-bottom: 2rem;\n}\n\n.password-reset-flex .mantine-TextInput-root,\n.password-reset-flex .mantine-Group-root {\n  width: 100%;\n}\n\n.password-reset-flex .mantine-Center-root {\n  margin-top: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
