import React, { useEffect, useState } from "react";
import "../assets/css/Custom.css";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { GetViewScan } from "../features/QrScann/View/viewSlice";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { AddBusinessToProfile } from "../features/businessCard/businessSlice";
import { ToastContainer, toast } from "react-toastify";
import HeaderBusinessCard from "../BusCard/Components/Header";
import ContactSectionBusiness from "../BusCard/Components/ContactSection";
import SocialMediaBusiness from "../BusCard/Components/SocialMedia";

export default function BcScreenView() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoadingCard, businessCard } = useSelector(
    (state) => state.qr_scanner
  );
  const [bcData, setBcData] = useState();
  const { token, isAuth, user } = useSelector((state) => state.auth);
  const { bc, addBcToProfile, isSucessBc } = useSelector((state) => state.bc);
  const navigate = useNavigate();

  useEffect(() => {
    const getparam = location;
    const sliceParam = getparam.pathname.split("/");
    const view_id = sliceParam[2];
    dispatch(GetViewScan(view_id));
    console.log("rendered again");
    setBcData(businessCard);
  }, [dispatch, location]);

  useEffect(() => {
    if (isSucessBc) {
      toast.success(addBcToProfile.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(addBcToProfile.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [addBcToProfile]);

  const handleAddToProfile = () => {
    const business_card_id = businessCard?.business_card?._id;

    console.log("business card ", business_card_id);
    if (!token && business_card_id) {
      // Redirect to login if not logged in, passing the business card ID in the URL
      navigate(`/login?businessCardId=${business_card_id}&added=true`);
    } else {
      // If logged in, add the business card to profile
      dispatch(AddBusinessToProfile({ businessCardId: business_card_id }));
    }
  };

  const handleDownload = () => {
    const vCardData = `BEGIN:VCARD
VERSION:3.0
FN:${businessCard?.business_card.business_name || ""}
TEL;TYPE=CELL:${businessCard?.business_card.phone_number}
EMAIL:${businessCard?.business_card.email_adress}
END:VCARD`;
    // Create a Blob from the vCard string data
    const blob = new Blob([vCardData], { type: "text/vcard" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.download = "contact.vcf"; // File name for the vCard

    // Programmatically trigger the download
    link.click();

    // Revoke the object URL to free memory
    URL.revokeObjectURL(url);
  };

  if (isLoadingCard) {
    return (
      <div className="qrc_page_wrapper custom_spinner">
        <BeatLoader color="#36d7b7" />
      </div>
    );
  }

  return (
    <div>
      <ToastContainer />
      <HeaderBusinessCard data={businessCard?.business_card} />
      <ContactSectionBusiness data={businessCard?.business_card} />
      <SocialMediaBusiness data={businessCard?.business_card} />
      <div className="extra_button_wrapper">
        <a className="qrc_addtocontact" onClick={handleDownload}>
          <div className="qrc_addtocontact_text">Ajouter au Contact</div>
          <div className="qrc_addtocontact_circle">
            <FaPlus color="#07031A" size={15} />
          </div>
        </a>
      </div>
      <div className="extra_button_wrapper">
        <a className="qrc_addtocontact_left" onClick={handleAddToProfile}>
          <div className="qrc_addtocontact_text">Ajouter au Portefeuille</div>
          <div className="qrc_addtocontact_circle">
            <FaPlus color="#07031A" size={15} />
          </div>
        </a>
      </div>
    </div>
  );
}
