import { Group, createStyles, TextInput } from "@mantine/core";

import "../../../assets/css/Profile.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateTempBCSocial } from "../../../features/businessCard/businessSlice";

const useStyles = createStyles((theme) => ({
  wrapper: {
    justifyContent: "space-between",
    alignItems: "center",
    background: "#DDBC46",
    padding: "2rem ",

    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "0",
    },
  },

  flex: {
    alignItems: "center",
    marginBottom: "1rem",
  },

  input: {
    marginBottom: "1rem",
    input: {
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "1px solid rgb(224, 224, 224)",
      borderRadius: "0",
      width: "100%",
      "&:focus": {
        borderColor: "#bcaf4b",
      },
    },
    label: {
      color: "#c5c7c9",
      fontWeight: "400",
    },
  },
}));

export default function Social() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [contactSocialData, setContactSocialData] = useState({});
  //events
  const handleInputChange = (event) => {
    setContactSocialData({
      ...contactSocialData,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    dispatch(updateTempBCSocial(contactSocialData));
  }, [contactSocialData]);
  return (
    <>
      <Group display="block" position="apart" mt="md" mb="xs">
        <TextInput
          className={classes.input}
          label="Facebook"
          size="sm"
          color="dimmed"
          value={contactSocialData.facebook}
          name="facebook"
          onChange={handleInputChange}
        />
        <TextInput
          className={classes.input}
          label="Linkedin"
          size="sm"
          color="dimmed"
          value={contactSocialData.linkedin}
          name="linkedin"
          onChange={handleInputChange}
        />
        <TextInput
          className={classes.input}
          label="Twitter"
          size="sm"
          color="dimmed"
          value={contactSocialData.twitter}
          name="twitter"
          onChange={handleInputChange}
        />
        <TextInput
          className={classes.input}
          label="Instagram"
          size="sm"
          color="dimmed"
          value={contactSocialData.instagram}
          name="instagram"
          onChange={handleInputChange}
        />
      </Group>
    </>
  );
}
