import { Avatar, Group, Container, Flex, Title, Box } from "@mantine/core";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
} from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import SocialMedia from "../../scan_side/SocialMedia";

const socialMediaIcons = {
  facebook: IconBrandFacebook,
  instagram: IconBrandInstagram,
  linkedin: IconBrandLinkedin,
  twitter: IconBrandTwitter,
};
function SocialMediaComponent({ data }) {
  const extraSmall = useMediaQuery("(max-width: 576px)");
  const smallScreen = useMediaQuery(
    "(min-width: 577px) and (max-width: 768px)"
  );
  const mediumScreen = useMediaQuery(
    "(min-width: 769px) and (max-width: 992px)"
  );

  console.log("social : ", data);
  const containerWidth = extraSmall
    ? "80%"
    : smallScreen
    ? "90%"
    : mediumScreen
    ? "90%"
    : "100%";

  const avatarSize = extraSmall
    ? 40
    : smallScreen
    ? 50
    : mediumScreen
    ? 55
    : 60;
  const iconsGap = extraSmall ? 15 : smallScreen ? 30 : mediumScreen ? 30 : 40;
  const titleMargLeft = extraSmall
    ? "2%"
    : smallScreen
    ? 0
    : mediumScreen
    ? 0
    : 25;
  const iconsMargLeft = extraSmall
    ? "2%"
    : smallScreen
    ? 0
    : mediumScreen
    ? 0
    : 25;
  const containerMargLeft = extraSmall
    ? "1%"
    : smallScreen
    ? 0
    : mediumScreen
    ? 0
    : 0;

  const dataMedia = data?.social_media_accounts || {};
  return (
    <Container style={{ width: containerWidth, margin: "auto" }}>
      <Flex
        w="100%"
        gap={extraSmall ? "md" : "md"}
        justify="center"
        align="flex-start"
        direction="column"
        wrap="wrap"
        my={10}
        ml={containerMargLeft}
      >
        <Title align="left" ml={titleMargLeft} pt={5} mt={-3} order={2}>
          Réseaux Sociaux
        </Title>
        <Group
          spacing={iconsGap}
          mt={-3}
          mb={10}
          ml={iconsMargLeft}
          position="center"
        >
          {Object.entries(dataMedia).map(([platform, username]) => {
            const Icon = socialMediaIcons[platform.toLowerCase()];

            if (!Icon) {
              console.error(`No icon found for ${platform}`);
              return null;
            }
            return (
              <SocialMedia
                key={platform}
                Icon={Icon}
                data={username}
                name={platform}
              />
            );
          })}
        </Group>
      </Flex>
    </Container>
  );
}

export default SocialMediaComponent;
