import HashLoader from "react-spinners/HashLoader";

const Loader = ({ isLoading }) => {
  // const isLoading = useSelector((state) => state.isLoading);
  const loaderStyle = {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    top: 0,
    right: 0,
    display: isLoading ? "flex" : "none",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 900,
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "relative",
        display: isLoading ? "flex" : "none",
      }}
    >
      <div style={loaderStyle} className="sweet-loading">
        <HashLoader
          color={"#bcaf4b"}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
};

export default Loader;
