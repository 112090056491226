import { Avatar, Box} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ResponsiveAvatar({ icon }) {
    const isSmallScreen = useMediaQuery('(max-width: 576px)');  // sm breakpoint
    const isMediumScreen = useMediaQuery('(min-width: 577px) and (max-width: 768px)');  // md breakpoint
    const isLargeScreen = useMediaQuery('(min-width: 769px)');  // lg breakpoint

    const avatarSize = isSmallScreen ? 34 : isMediumScreen ? 50 : isLargeScreen ? 55 : 80;

    return (
    <Avatar radius="xl" size={avatarSize} >
            <Box
            sx={{
              backgroundColor: 'black',  // Set the background color for the icon
              padding: '20px',  // Adjust the padding
              // borderRadius: '50%',  // Make it circular
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}>
              <FontAwesomeIcon icon={icon} style={{ color: 'white'}}/>
            </Box>
        
    </Avatar>
  );
}

export default ResponsiveAvatar;
